// FIRST TOPOJSON 5 REGIONS 

export default function updateNamesPositionFor5Regions(array) {
    // Dict with replace values
    const replacements = {
        "North America": "North America",
        "Europe": "Europe",
        "Asia-Pacific": "Asia Pacific",
        "Asia Pacific":"Asia Pacific",
        "Middle-East & Africa": "Middle East & Africa",
        "Middle East & Africa":"Middle East & Africa",
        "Latin America": "Latin America",
        // adding for eenaml EENAML  
        "Europe EU-27": "Europe EU-27",
        "Europe Non EU-27": "Europe Non EU-27",
        // "Asia-Pacific": "Asia-Pacific"
    };

    return array.map(subarray => {
        const updatedFirstPosition = replacements[subarray[0]] || subarray[0];
        return [updatedFirstPosition, ...subarray.slice(1)];
    });
}