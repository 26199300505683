<template>
    <Button
        class="p-button-success" 
        :loading="loading"
        @click="openConfirmationCreateContractSummary()" 
    >
        <label style="margin-right: 0.5rem;">Create Contract Summary</label>
        <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
    </Button>

    <Dialog 
        v-model:visible="openSummaryContractDialog"
        :style="{width: '30%'}"
        header="Override Contract Summary"
        :modal="true"
    >
        <div class="confirmation-content d-flex flex-row">
            <span>
                Are you sure you want to override <strong>contract summary.</strong> 
            </span>
        </div>
        <template #footer>
            <Button 
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="closeDialog()"
            />
            <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                :loading="loading"
                @click="createContractSum"
            >
            <!-- :loading="" -->

            </Button>

        </template>

    </Dialog>

</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from 'vue-toastification';
import { getChatGPTForCreateContractSummary } from '../modules/admin/document/services/document.service';


const toast = useToast();

export default {
    name:'summaryContractCreator',
    props:['editDocumentFormText','contractSummary'],
    emits: ['createContractsummary','changeChatGPTRequestLoading'],
    components:{
        Button,
        Dialog,
    },
    data(){
        return{
            openSummaryContractDialog: false,
            loading:false,
        }
    },
    mounted() {
        
    },

    computed:{

    },
    methods: {
        async openConfirmationCreateContractSummary(){
            if(!this.contractSummary){
                await this.createContractSum()        
            }
            else{
                this.openSummaryContractDialog = true;
            }
        },
        closeDialog(){
            this.openSummaryContractDialog = false;
        },
        cleanHtmlTags(html) {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = html;
            return tempElement.textContent || tempElement.innerText || '';
        },
        async createContractSum(){
            try {
                this.loading= true;
                this.$emit("changeChatGPTRequestLoading");
                const cleanText = this.cleanHtmlTags(this.editDocumentFormText);
                // this.$emit("createContractsummary", { data: cleanText });
                const response =  await getChatGPTForCreateContractSummary(cleanText);
                this.$emit("createContractsummary", { data: response.data.result });
                this.$emit("changeChatGPTRequestLoading");
                this.loading= false
                this.closeDialog()
            } catch (error) {
                toast.error(error.message)
                console.error('error:',error);
            }
        },

        
    },
    watch:{

    }
    
}
</script>

<style>
    
</style>