// call 
// import {convertValueDisplayOption, toFixDecimate, roundTo, toFixValueOptionDecimate, formatNumber, formatCagr} from '@/shared/helpers/output/outputFormat';
function convertValueDisplayOption(data, valueDisplayOptionOutput){
    if (valueDisplayOptionOutput === 'billions') {
        return data / 1000;
    }
    else {
        return data
    }
}

function toFixDecimate(data, decimalsOptionOutput){
    if (decimalsOptionOutput === '0.') {
        if (Number.isInteger(data)) {
            return data
        }
        else {
            return Math.round(data);
        }
    }
    if (decimalsOptionOutput === '.0') {
        return roundTo(data, 1).toFixed(1);
    }
    if (decimalsOptionOutput === '.00') {
        return roundTo(data, 2).toFixed(2);
    }
    if (decimalsOptionOutput === '.000') {
        return roundTo(data, 3).toFixed(3);
    }
}

function roundTo(num, decimals){
    let factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
}

function toFixValueOptionDecimate(data , valueDisplayOptionOutput, decimalsOptionOutput  ){
    const valueDisplay = convertValueDisplayOption(data, valueDisplayOptionOutput );
    return toFixDecimate(valueDisplay, decimalsOptionOutput)
}

function formatNumber(data) {
    if (data) {
        // split int and decimals of number
        let [int, decimal] = data.toString().split('.');

        //format int with , 
        int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return decimal ? `${int}.${decimal}` : int
    }
    else {
        return data
    }
}

function formatCagr(value, FY, LY) {
    if (FY === 0 || LY === 0  || value === -100) {
        return '-';
    }
    if (FY === LY) {
        return "0.0%"
    }
    return `${value.toFixed(1)}%`;
}

export { 
    convertValueDisplayOption, 
    toFixDecimate,
    roundTo,
    toFixValueOptionDecimate,
    formatNumber,
    formatCagr
}