<template>
    <div>
        <h1> ExportSingleOutputSummary</h1>

        <ExportForecastDataSummaryToWordSingle
            :output="outputSingle"
            :topicName="topic.name"
            :valueDisplayOption="valueDisplayOption"
            :decimalsOption="decimalsOption"
            @endDataFinish="endDataFinish()"
        />

    </div>
</template>
<script>
import { exportSummaryToWord } from './exportToWord.service';
import { useToast } from 'vue-toastification';

import ExportForecastDataSummaryToWordSingle from './ExportForecastDataSummaryToWordSingle.vue'


const toast = useToast();

export default {
    name: 'ProgramOutputExportSummaryPreview',
    components:{
        ExportForecastDataSummaryToWordSingle,
    },
    emits: ['setFalseLoadingExportToDocx'],
    props: [
        'outputSingle',
        'topic',
        'valueDisplayOption',
        'decimalsOption',
        'exportLastYear',
        'exportFirstYear',
        'scenarioName'
    ],
    data(){
        return {
            dataFinish: false,
        }
    },
    mounted(){
        // console.log('outputSingle',this.outputSingle);
        // console.log('topic',this.topic);
        // console.log('valueDisplayOption',this.valueDisplayOption);
        // console.log('decimalsOption',this.decimalsOption);
        // console.log('exportLastYear',this.exportLastYear);
        // console.log('exportFirstYear',this.exportFirstYear);
        // console.log('scenarioName',this.scenarioName);
    },
    computed:{
        exportOutputTables(){
            return this.$store.getters.obtainExportOutputTables;
        },
        exportOutputCharts(){
            return this.$store.getters.obtainExportOutputCharts; 
        },
        exportOutputRegionGlobalChart(){
            return this.$store.getters.obtainExportOutputRegionGlobalChart;
        }
    },
    methods:{
        endDataFinish(){
            this.dataFinish = true;
        },
        setFalseLoadingExportToDocx(){
            this.$emit("setFalseLoadingExportToDocx")
        },
        async exportToDoc(){
            // TODO prepare for convert request

            let exportOutputTables = JSON.parse(JSON.stringify(this.exportOutputTables))
            let exportOutputCharts = JSON.parse(JSON.stringify(this.exportOutputCharts))
            let exportOutputRegionGlobalChart = JSON.parse(JSON.stringify(this.exportOutputRegionGlobalChart))
            // console.log('exportOutputRegionGlobalChart',exportOutputRegionGlobalChart);
            

            // console.log('exportOutputTables',exportOutputTables);
            // console.log('exportOutputCharts',exportOutputCharts);

            let tables = [];
            exportOutputTables.map( (exportOutputTable) => tables.push(exportOutputTable.content) )

            let charts = exportOutputCharts.reduce((acc, item) => {
                if (!acc[item.order]) {
                    acc[item.order] = [];
                }
                // Añadimos el elemento al grupo correspondiente
                acc[item.order].push(item);
                return acc;
            }, []);


            const preHtml = `<div>`;
            const postHtml = `</div>`;
            
            let bodyContent = '';


            for (let index = 0; index < tables.length; index++) {

                // title1 
                bodyContent += `<h2> ${this.topic.name} market summary by ${this.exportOutputTables[index].segmentation[0]} </h2>`
                
                // // chart ss01
                bodyContent += `<h3> ${this.topic.name} market summary by ${this.exportOutputTables[index].segmentation[0]} </h3>`
                bodyContent += `${charts[index][0].content}`
                
                // // chart ss02 
                bodyContent += `<h3> ${this.topic.name} Size by ${this.exportOutputTables[index].segmentation[0]} </h3>`
                bodyContent += `${charts[index][1].content}`

                // // chart ss03 
                bodyContent += `<h3> ${this.topic.name} CAGR by ${this.exportOutputTables[index].segmentation[0]} in US$ ${this.valueDisplayOption} </h3>`
                bodyContent += `${charts[index][2].content}`

                if(index===0){ // for region global chart
                    // // chart ss04 
                    bodyContent += `<h3>Market volumes over the forecast period per region</h3>`
                    // console.log('name',exportOutputRegionGlobalChart[0].name); //this log is saved as Market volumes ∑25-33 per region check it 
                    bodyContent += `${exportOutputRegionGlobalChart[0].content}`
                }

                // Table
                bodyContent += `<h3> ${this.topic.name} market summary by ${this.exportOutputTables[index].segmentation[0]} in US$ ${this.valueDisplayOption} </h3>`
                bodyContent += `${tables[index]}`
            }

            const fullHtml = preHtml + bodyContent + postHtml


            try {
                const formData = new FormData();
                formData.append('htmlContent',fullHtml);

                await exportSummaryToWord(formData, this.topic.name, this.scenarioName);
                toast.success('Successfully download');

            } catch (error) {
                console.error('Error Convertion:', error);
                toast.error('Error DOCX convertion');
            }
            finally{
                this.$emit("setFalseLoadingExportToDocx");    
            }
            
        },
    },
    watch:{
        dataFinish:{
            async handler(newVal){
                if(newVal){
                    await this.exportToDoc();
                }
            },
        }
    },
}
</script>
<style>
    
</style>