// Vuex output store Design for export To Word
import {
    getOutputValues,
    getSingleOutputValues
} from '../../modules/admin/topic/services/topic.service';

const exportOutputModule = {
    state: () => ({
        exportOutput: [],
        exportSingleOutput: [],

        exportOutputCharts: [],
        exportOutputTables: [],

        exportOutputVersusCharts: [],
        exportOutputVersusTables: [],

        exportOutputRegionGlobalChart: [],

    }),
    getters: {
        obtainExportOutput(state) {
            return state.exportOutput
        },
        obtainExportSingleOutput(state) {
            return state.exportSingleOutput
        },
        // Export single Getters 
        obtainExportOutputCharts(state) {
            return state.exportOutputCharts
        },
        obtainExportOutputTables(state) {
            return state.exportOutputTables
        },
        // Export Versus Getters 
        obtainExportOutputVersusCharts(state) {
            return state.exportOutputVersusCharts
        },
        obtainExportOutputVersusTables(state) {
            return state.exportOutputVersusTables
        },
        // Export Region Global Chart
        obtainExportOutputRegionGlobalChart(state){
            return state.exportOutputRegionGlobalChart
        },

    },
    mutations: {
        setExportOutput(state, exportOutput) {
            state.exportOutput = exportOutput
        },
        cleanExportOutput(state) {
            state.exportOutput = [];
        },
        setExportSingleOutput(state, exportSingleOutput) {
            state.exportSingleOutput = exportSingleOutput
        },
        cleanExportSingleOutput(state) {
            state.exportSingleOutput = [];
        },

        // Export single Mutation 
        addExportOutputCharts(state, exportOutputCharts) {
            state.exportOutputCharts.push(exportOutputCharts);
        },
        cleanExportOutputCharts(state) {
            state.exportOutputCharts = []
        },
        addExportOutputTables(state, exportOutputTables) {
            state.exportOutputTables.push(exportOutputTables);
        },
        cleanExportOutputTables(state) {
            state.exportOutputTables = []
        },

        // Export Versus Mutation 
        addExportOutputVersusCharts(state, exportOutputVersusCharts) {
            state.exportOutputVersusCharts.push(exportOutputVersusCharts);
        },
        cleanExportOutputVersusCharts(state) {
            state.exportOutputVersusCharts = []
        },
        addExportOutputVersusTables(state, exportOutputVersusTables) {
            state.exportOutputVersusTables.push(exportOutputVersusTables);
        },
        cleanExportOutputVersusTables(state) {
            state.exportOutputVersusTables = []
        },

        // Export Region Global Chart
        addExportOutputRegionGlobalChart(state, exportOutputRegionGlobalChart) {
            state.exportOutputRegionGlobalChart.push(exportOutputRegionGlobalChart);
        },
        cleanExportOutputRegionGlobalChart(state) {
            state.exportOutputRegionGlobalChart = []
        },

    },
    actions: {
        async obtainExportOutput({ commit }, { topicId, form }) {
            const output = await getOutputValues(topicId, form)
            commit('setExportOutput', output)
        },
        cleanExportOutput({ commit }) {
            commit('cleanExportOutput');
        },
        async obtainExportSingleOutput({ commit }, { topicId, form }) {
            const output = await getSingleOutputValues(topicId, form)
            commit('setExportSingleOutput', output)
        },
        cleanExportSingleOutput({ commit }) {
            commit('cleanExportSingleOutput');
        },

        // Export Single Actions 
        addExportOutputCharts({ commit }, chart) {
            commit('addExportOutputCharts', chart);
        },
        cleanExportOutputCharts({ commit }) {
            commit('cleanExportOutputCharts');
        },
        addExportOutputTables({ commit }, chart) {
            commit('addExportOutputTables', chart);
        },
        cleanExportOutputTables({ commit }) {
            commit('cleanExportOutputTables');
        },
        cleanAllExportOutput({ commit }) {
            commit('cleanExportOutputCharts', 'cleanExportOutputTables');
        },

        // Export Versus Actions
        addExportOutputVersusCharts({ commit }, chart) {
            commit('addExportOutputVersusCharts', chart);
        },
        cleanExportOutputVersusCharts({ commit }) {
            commit('cleanExportOutputVersusCharts');
        },
        addExportOutputVersusTables({ commit }, chart) {
            commit('addExportOutputVersusTables', chart);
        },
        cleanExportOutputVersusTables({ commit }) {
            commit('cleanExportOutputVersusTables');
        },
        cleanAllExportVersusOutput({ commit }) {
            commit('cleanExportOutputVersusCharts', 'cleanExportOutputVersusTables');
        },

        // Export Region Global Chart
        addExportOutputRegionGlobalChart({ commit }, chart) {
            commit('addExportOutputRegionGlobalChart', chart);
        },
        cleanExportOutputRegionGlobalChart({ commit }) {
            commit('cleanExportOutputRegionGlobalChart');
        },
    }
}

export default exportOutputModule;