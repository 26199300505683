<template>
    <div ref="chartContainer" class="chart" style="height: 100%;position: relative;">
    </div>
    <ExportSettingsDialog 
        :algo="'something4'"
        @exportToSvg="exportToSvgEmit"
    />
</template>

<script>
import Anychart from 'anychart'

import './AnychartThemes/market_forecast.js'
import updateNamesPositionFor5Regions from '../../../../../../../shared/helpers/globalMap/validateToTopoJson.js';
import ExportSettingsDialog from './ExportSettingsDialog.vue';

import topoJsonNEALM from "../../../../../../../assets/topic/topoJsonNEALM.json";
import topoJsonEENAML from "../../../../../../../assets/topic/topoJsonEENAML.json"

export default {
    props: [
        'toFigureGlobalData',
        'toFigureGlobalDataGeoValues',
        'segmentationTitles',
        'sumTitle',
        'segmentationRegionNameAbr',
        'valueDisplayOption',
        'decimalsOption',
        'years',

        'topicName',

        'exportToWordSummary',
        'segmentationName',
        'index1'
    ],
    emits: ['addChartsForExportWord'],
    name: 'VueAnychartGlobalFigure1',
    components:{
        ExportSettingsDialog,

    },
    data(){
        return {
            chart:null,
            map:null,
            regionError: false,
        }
    },
    mounted() {
        this.init();
        // console.log('segmentationTitles', this.segmentationTitles);
        // console.log('sumTitle', this.sumTitle);
        // console.log('segmentationRegionNameAbr', this.segmentationRegionNameAbr);
        // console.log('toFigureGlobalDataGeoValues',this.toFigureGlobalDataGeoValues);
        // console.log('valueDisplayOption',this.valueDisplayOption);
        // console.log('decimalsOption',this.decimalsOption);
        // console.log('years',this.years);

        // 

        // console.log('toFigureGlobalData',this.toFigureGlobalData);
        // console.log('toFigureGlobalDataGeoValues',this.toFigureGlobalDataGeoValues);
        // console.log('segmentationTitles',this.segmentationTitles);
        // console.log('sumTitle',this.sumTitle);

        // console.log('segmentationRegionNameAbr',this.segmentationRegionNameAbr);
        // console.log('valueDisplayOption',this.valueDisplayOption);
        // console.log('decimalsOption',this.decimalsOption);
        // console.log('years',this.years);

        // console.log('topicName',this.topicName);
        // console.log('exportToWordSummary',this.exportToWordSummary);
        // console.log('segmentationName',this.segmentationName);
        // console.log('index1',this.index1);

        if(this.exportToWordSummary && !this.regionError){
            this.addChartsForWord();
        }

    },

    computed:{

    },

    methods: {
        validateTopoJsons(dataSet){
            let checkTopoJsons = dataSet
            let result = checkTopoJsons.map(subarray => subarray[0]).sort().join(",");

            // example ground based 
            let fiveRegionsStackNEALM = [
                "North America",
                "Europe",
                "Asia Pacific",
                "Middle East & Africa",
                "Latin America"
            ].sort().join(",");
            
            // example High Density Interconnect PCB technology
            let fiveRegionsStackEENAML = [
                "Europe EU-27",
                "Europe Non EU-27",
                "North America",
                "Asia Pacific",
                "Middle East & Africa",
                "Latin America"
            ].sort().join(",");

            // Implementar el switch
            switch (result) {
                case fiveRegionsStackNEALM:
                    return topoJsonNEALM; 
                case fiveRegionsStackEENAML:
                    return topoJsonEENAML;
                default:
                    return false; // Otro array
                // Si quieres agregar el caso para devolver 3 en el futuro, solo añade aquí
            }
        },

        init(){
            try {
                let _Anychart = Anychart;
                this.chart = _Anychart;

                // const dataSet = this.chart.data.set(
                //     [
                //         ["Europe", 37.0, 33239],
                //         ["Middle East & Africa", 21.0, 18892],
                //         ["Asia Pacific", 19.7, 17678],
                //         ["North America", 19.3, 17337],
                //         ["Latin America", 3.0, 2729],
                //     ]
                // );
                
                const replacedNames = updateNamesPositionFor5Regions(this.toFigureGlobalData);

                const dataSet = this.chart.data.set( replacedNames );

                this.map = this.chart.map();

                // Apply theme
                this.chart.theme('marketForecast')

                // LICENSE KEY
                this.chart.licenseKey('marketforecast.com-f857f242-9dccc95c'); 
                
                // CREDITS 
                const currentYear = new Date().getFullYear();
                this.map
                    .credits() 
                    .enabled(true)
                    .url('https://www.marketforecast.com')
                    .logoSrc('https://www.marketforecast.com/favicon.ico')
                    .text(
                        `© Market Forecast ${currentYear}`
                    );

                const topoJsonValidated =  this.validateTopoJsons(replacedNames)
                this.map.geoData(topoJsonValidated);

                this.map.maxBubbleSize(60);
                this.map.minBubbleSize(30);

                // const series_1 = this.map.bubble(dataSet.mapAs({ id: 0, size: 5 })); 
                const series_1 = this.map.bubble(dataSet.mapAs({ id: 0, size: 5 })); // 5position is the formula map 
                series_1.name("% Market volumes 2024-2032");

                // test1 #region
                // series_1.tooltip(true);
                // series_1.labels(true);

                //     series_1.labels().fontColor('white');
                //     series_1.labels().fontSize(18);
                // test1 #Endregion

                series_1.tooltip(true);
                series_1.labels(true);

                // customize labels 
                // series_1
                //     .labels()
                //     .fontColor('white')
                //     .fontSize(18)
                //     .hAlign('center') // Alinear horizontalmente al centro
                //     .vAlign('middle') // Alinear verticalmente al centro
                //     .format((context) => {
                //         // test1
                //         const size = context.getData('size');
                //         const region = context.getData('id');
                //         return `${region}\n${size}%`; 
                //     });

                // console.log('this.segmentationRegionNameAbr',this.segmentationRegionNameAbr);
                // console.log('replacedNames',replacedNames);
                
                series_1
                    .labels()
                    .useHtml(true)
                    .fontColor('white')
                    .hAlign('center') 
                    .vAlign('middle') 
                    .format((context) => {
                        const size = context.getData('size');
                        // const region = context.getData('id');
                        const index = context.index;
                        const regionAbr = this.segmentationRegionNameAbr[index]

                        return `
                            <span style="font-size: 10px;">${regionAbr}</span><br>
                            <span style="font-size: 18px; font-weight: bold;">${size}%</span>
                        `;
                    });


                // set the color of bubbles
                series_1.normal().fill("#003A70", 1.0);
                series_1.hovered().fill("#003A70", 0.8);
                series_1.selected().fill("#003A70", 0.9);   
                // Disable the bubble series from appearing in the legend
                
                // series_1.legendItem().enabled(true);
                    
                //     // format the labels
                //         series_1.labels().format("{%size}%");

                const series_2 = this.map.choropleth(dataSet.mapAs({ id: 0, marketSizeFY:1, marketSizeLY:2,sumFYLY:3, cagr:4,  }));
                series_2.name("RegionOverview ");

                const valueDisplayOption = this.valueDisplayOption

                const FY = this.years[0]
                const LY = this.years[1]
                const sumTitle = this.sumTitle

                series_2.tooltip().format(function () {
                    const marketSize2024 = this.getData('marketSizeFY'); 
                    const marketSize2032 = this.getData('marketSizeLY'); 
                    const sum24To32 = this.getData('sumFYLY'); 
                    const cagr = this.getData('cagr'); 

                    return `Market size ${FY}: ${marketSize2024} US$ ${valueDisplayOption}\n` +
                        `Market size ${LY}: ${marketSize2032} US$ ${valueDisplayOption}\n` +
                        `${sumTitle}: ${sum24To32} US$ ${valueDisplayOption}\n` +
                        `CAGR ${FY}-${LY}: ${cagr}`;
                });

                // this.map.title("Global Map - 5 Regions - Europe, North America, Asia Pacific, Middle East & Africa, Latin America");
                this.map.title(`Market share ${this.sumTitle} by ${this.segmentationTitles} `);
                this.map.container(this.$refs.chartContainer).draw();
                
            } catch (error) {
                console.error(error);
                this.regionError = true;
            }
            
        },

        exportToSvgEmit({width, height}){
            this.exportToSvg(width, height)
        },

        exportToSvg(width, height){
            this.map.saveAsSvg(
                {
                    "width":width,
                    "height":height,
                    "filename":'ST01_'+this.topicName+'_'+`Market_share_${this.sumTitle}_by_${this.segmentationTitles}`,
                }
            )
        },

        addChartsForWord(){
            this.map.title(false); //remove title for exportToWord

            this.map.width(800);        //800
            this.map.height(400);       //650 
            this.map.background().stroke(null);

            let svg = this.map.toSvg();

            // // Convert svg string to an DOM object
            let parser = new DOMParser();
            let svgDoc = parser.parseFromString(svg, 'image/svg+xml');

            svgDoc.querySelectorAll('text').forEach((textElement) => {
                // obtain all <tspan> from <text> childs
                const tspanElements = Array.from(textElement.querySelectorAll('tspan'));

                let firstLineFound = false; //first flag line
                let secondLineFound = false; //second flag line

                // clean content of original text
                textElement.textContent = '';

                tspanElements.forEach((tspan) => {
                    const textContent = tspan.textContent.trim(); // clean emptyContnet clean spaces

                    // Apply styles 
                    if (!firstLineFound && textContent.length > 0) {
                        tspan.setAttribute('font-size', '10px'); 
                        tspan.setAttribute('x', textElement.getAttribute('x') || '0'); 
                        tspan.setAttribute('dy', '-0.6em'); 
                        textElement.appendChild(tspan); 
                        firstLineFound = true; 
                    } else if (!secondLineFound && textContent.length > 0) {
                        tspan.setAttribute('font-size', '18px'); 
                        tspan.setAttribute('font-weight', 'bold'); 
                        tspan.setAttribute('x', textElement.getAttribute('x') || '0'); 
                        tspan.setAttribute('dy', '1em');
                        textElement.appendChild(tspan); 
                        secondLineFound = true; 
                    } else if (textContent.length === 0) {
                        // clean empty <tspan>
                        tspan.remove();
                    }
                });

                //Adjust y axis 
                const currentY = parseFloat(textElement.getAttribute('y')) || 0;
                textElement.setAttribute('y', currentY + 6.5); 
            });

            // // get root <svg >
            let svgElement = svgDoc.documentElement;

            // // change width and height 
            svgElement.setAttribute('width', '800');            //800
            svgElement.setAttribute('height', '400');           //650

            // // serialize svg to string 
            let serializer = new XMLSerializer();
            let svgString = serializer.serializeToString(svgElement);


            // this.sumTitle
            const exportContent = {
                // content: this.column.toSvg(),
                content: svgString,
                name: `Market volumes ${this.sumTitle} per region`,
                segmentation: this.segmentationName,
                order: this.index1
            } ;
            this.$emit("addChartsForExportWord", {exportContent: exportContent, order:exportContent.order})

        },

        endLastStepForExportToWord(){
            this.$emit("finishLoading") // finish loading for export To docx
        },
        
    },
    watch:{

    },
    beforeUnmount() {
        
    },
}
</script>

<style>
</style>