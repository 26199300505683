<template>
    <div class="container">
        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left" @click="goBack" />
        <template v-if="loadingContractPerCompany">
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="card">
                    <header class="p-3">
                        <h4 v-if="selectedTopic"> Contracts per Company for <strong> {{ selectedTopic.name }}</strong> </h4>
                    </header>
                    <main class="mx-5">
                        <div v-if="contractPerCompany">
                            <div v-for="(cPc, index) in contractPerCompany" :key="index">
                                <div v-if="cPc.documentCompanies.length > 0">
                                    <h3 class="mt-3"> <strong>{{ cPc.name }}</strong> </h3>
                                    <div 
                                        v-for="( cCompany, cPcIndex) in cPc.documentCompanies" 
                                        :key="cPcIndex"
                                        style="
                                            display: flex; 
                                            align-items: center; 
                                            margin-bottom: 1rem; 
                                        ">
                                        <div style="width: 50%;">
                                            <label for=""> <strong>Contract :</strong> {{ cCompany.document.contractSummary }}
                                            </label>
                                        </div>
                                        <div>
                                            <Button class="p-button-rounded p-button-success mx-2" icon="pi pi-eye"
                                                @click="openEditDocument(cCompany.documentId)" />
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <h3 style="color: red;"> <strong>Contracts Not Found</strong> </h3>
                        </div>
                    </main>
                    <footer class="col-12 py-4 mx-5">
                        <Button label="Export data" class="ml-3" style="background-color: blue;" @click="exportData()" :loading="loadingExportToExcel" />
                    </footer>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';

import { useToast } from 'vue-toastification';
const toast = useToast(); 

// outputService
import {exportToExcelContractPerCompanies }  from './../../../../company/services/company.service';

export default {
    name: 'ContractPerCountryPage',
    components: {
        ProgressSpinner,
        Button,
    },
    setup: () => ({
        v$: useVuelidate()
    }),
    data() {
        return {
            // loading
            loadingContractPerCompany: true,
            loading: true,
            loadingExportToExcel: false, 
        }
    },
    validations() {
        return {}
    },
    async created() {
        await this.getContractPerCountry();
    },
    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        contractPerCompany() {
            return this.$store.getters.obtainContractPerCompany;
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getContractPerCountry() {
            try {
                this.loadingContractPerCompany = true;
                await this.$store.dispatch('obtainContractsPerCompany', this.selectedTopic.topicId);
                this.loadingContractPerCompany = false
            } catch (error) {
                console.error(error);
                this.loadingContractPerCompany = false
            }
        },
        async exportData() {
            this.loadingExportToExcel = true
            try {
                await exportToExcelContractPerCompanies(this.selectedTopic);
                this.loadingExportToExcel = false
            } catch (error) {
                this.loadingExportToExcel = false
                toast.error(error.message);
            }
            
        },
        openEditDocument(documentId) {
            window.open(`../../documents/edit/${documentId}`, '_blank')
        }
    },
    watch: {
        async selectedTopic(newValue) {
            if (newValue) {
                await this.getContractPerCountry();
            }
        }
    }
}

</script>

<style scoped></style>