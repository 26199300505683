<template>
    <div class="container">
        <Button 
            class="p-button-raised p-button-rounded my-3 p-button-danger" 
            icon="pi pi-arrow-left" 
            @click="goBack()" 
        />
        <template v-if="!loading">
            <!-- Export This div -->
            <div class="row" id="exportCompanyProfile">
                <header class="p-3" id="removeTag">
                    <h4> Companies Profile</h4>
                </header>

                <div class="mb-5" id="removeTag" >
                    <Button
                        @click="exportToWord()"
                        :loading="loadingExportToWord"
                        label="Export To Word"
                    />
                </div>

                <div class="card">
                    <!-- Leading Companies -->
                    <header class="p-3">
                        <h1> Leading Companies</h1>
                    </header>
                    <main class="mx-5">
                        <div 
                            class="mb-3"
                            v-for="company,index in leadingCompanies" 
                            :key="'company '+index"
                        >
                            <!-- TITLE -->
                            <div style="display: flex; align-items: center; gap: 1rem; justify-content: space-between;">
                                <Divider align="left">
                                    <h2>
                                        {{ company.name}} 
                                    </h2>
                                </Divider>
                                <div id="removeTag">
                                    <Button 
                                        @click="goToCompany(company.companyId)"
                                        style="padding: 0.4rem;"
                                        icon="pi pi-reply" 
                                    > 
                                        <!-- label="Redirect Company  " -->
                                        <!-- Go to {{ company.name}}   -->
                                    </Button>
                                </div>
                            </div>
                            
                            
                            <!-- INTRODUCTION -->
                            <h3 :id="(company.description) ? null :'removeTag'" >
                                Introduction
                            </h3>
                            <!-- GENERAL DESCRIPTION -->

                            <!--ToAsk using v-html can Have XSS DomPurify -->
                            <div 
                                v-html="company.description || 'No description available'" 
                                :class="{ 'text-red': !company.description }" 
                                :id="(company.description)? null :'removeTag'"
                            >
                            </div>


                            <!-- TOPIC NAME -->
                            <h3 :id="(showTopicDescription(company , selectedTopic.topicId))?null:'removeTag'">
                                {{ selectedTopic.name  }} - Products and Services
                            </h3>

                            <div 
                                v-html="showTopicDescription(company , selectedTopic.topicId) || 'No description available'" 
                                :class="{ 'text-red': !showTopicDescription(company , selectedTopic.topicId) }"
                                :id="(showTopicDescription(company , selectedTopic.topicId))? null :'removeTag'"
                            >
                            </div>

                            <!-- Contracts -->

                            <h3 :id="(company.documentCompanies.length > 0) ? null :'removeTag'"> Recent Developments and Contracts </h3>

                            <div v-if="company.documentCompanies.length > 0">
                                <div 
                                    class="mb-3 mt-3 d-flex "
                                    v-for="companyDocumentCompanies, cDcindex in sortByDocumentDate(company.documentCompanies) " 
                                    :key="'company '+index+'contract'+cDcindex"
                                >
                                    <strong>{{ convertDateFormatMonthYear(companyDocumentCompanies.document.documentDate) }} : </strong>

                                    <div>
                                        {{ companyDocumentCompanies.document.contractSummary }}
                                    </div>
                                    <!-- <div v-html="companyDocumentCompanies.document.contractSummary">
                                    </div> -->
                                </div>
                            </div>
                            <div v-else style="color:red;" id="removeTag">
                                No contracts available
                            </div>

                            <!-- Swot analyss -->
                            <!-- Test, List OF Swot -->

                            <h3 :id="(company.companyAnalysesItems.length > 0)?null:'removeTag'  ">SWOT Analysis</h3>

                            <div v-if="company.companyAnalysesItems.length > 0 ">
                                <table class="cp-table">
                                    <tr>
                                        <td class="cp-td">
                                            <strong> Strength:</strong>
                                            <ul 
                                                class="cp-ul"
                                                v-for="strength, sIndex in showSwotList( company.companyAnalysesItems, 0 ) " 
                                                :key="'company '+index+'strength'+sIndex"
                                            >
                                                <li>
                                                    {{ strength.companyAnalysesItem }}
                                                </li>
                                            </ul>
                                        </td>

                                        <td class="cp-td">
                                            <strong>Weakness: </strong>
                                            <ul
                                                class="cp-ul"
                                                v-for=" weakness, wIndex in showSwotList( company.companyAnalysesItems, 1 ) " 
                                                :key="'company '+index+'weakness'+wIndex"
                                            >
                                                <li>
                                                    {{ weakness.companyAnalysesItem }}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="cp-td">
                                            <strong> Opportunity:</strong>
                                            <ul 
                                                class="cp-ul"
                                                v-for=" opportunity, oIndex in showSwotList( company.companyAnalysesItems, 2 ) " 
                                                :key="'company '+index+'opportunity'+oIndex"
                                            >
                                                <li>
                                                    {{ opportunity.companyAnalysesItem }}
                                                </li>
                                            </ul>
                                        </td>

                                        <td class="cp-td">
                                            <strong> Threat: </strong>
                                            <ul
                                                class="cp-ul"
                                                v-for=" threat, tIndex in showSwotList( company.companyAnalysesItems, 3 ) " 
                                                :key="'company '+index+'threat'+tIndex"
                                            >
                                                <li>
                                                    {{ threat.companyAnalysesItem }}
                                                </li>
                                            </ul>
                                        </td>


                                    </tr>

                                </table>
                            </div>

                            <div v-else style="color:red;" id="removeTag">
                                No SWOT Analysis available
                            </div>
                            
                        </div>
                    </main>

                    <!-- Important players -->
                    <header class="p-3">
                        <h2>
                            Other companies of interest 
                        </h2>
                    </header>
                    <main class="mx-5">
                        <div 
                            class="mb-3"
                            v-for="company,index in importantPlayerCompanies" 
                            :key="'companyIP'+index"
                        >
                            <!-- TITLE -->
                            <div style="display: flex; align-items: center; gap: 1rem; justify-content: space-between;">
                                <Divider align="left">
                                    <h2>
                                        {{ company.name}} 
                                    </h2>
                                </Divider>
                                <div id="removeTag">
                                    <Button 
                                        @click="goToCompany(company.companyId)"
                                        style="padding: 0.4rem;"
                                        icon="pi pi-reply" 
                                    > 
                                    </Button>
                                </div>
                            </div>
                            <!-- GENERAL DESCRIPTION -->

                            <div 
                                v-html="company.description || 'No description available'" 
                                :class="{ 'text-red': !company.description }"
                                :id="(company.description)?null:'removeTag'"
                            >
                            </div>

                            <!-- TOPIC description -->

                            <div 
                                v-html="showTopicDescription(company , selectedTopic.topicId) || 'No Topic description available '" 
                                :class="{ 'text-red': !showTopicDescription(company , selectedTopic.topicId) }"
                                :id="(showTopicDescription(company , selectedTopic.topicId))? null :'removeTag' "
                            >
                            </div>

                            <div class="mt-4 mb-4">
                                <Divider></Divider>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <!-- end Here -->
        </template>

        <template v-else>
            <div class="row">
                <div class="col-12 py-4 d-flex justify-content-center">
                    <ProgressSpinner />
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';
import { exportToWordCompanyProfiles } from '../components/ExportForecastDataToWord/exportToWord.service';
import { useToast } from 'vue-toastification';

const toast = useToast();
export default {
    name:'CompanyProfiles',
    components:{
        Button,
        ProgressSpinner,
        Divider,
    },
    data(){
        return{
            loading:true,
            loadingContractPercompany: true,

            loadingExportToWord:false,


            // CompanyName
            leadingCompaniesNames: [],
            importantPlayerCompaniesNames:[],

            // supplyChain
            supplyChain: [
                {
                    id: 0,
                    name: 'OEM',
                },
                {
                    id:1,
                    name: 'Tier 1'
                },
                {
                    id: 2,
                    name: 'Tier 2',
                },
                {
                    id:3,
                    name: 'Tier 3'
                },
                {
                    name:'not Selected'
                }
            ],

            // companyTopicProfile
            companyTopicProfile: [
                {
                    id:0,
                    name: "Leading Companies",
                    description: "Companies with dominant market share - extended profile in study "
                },

                {
                    id:1,
                    name: "Important Players",
                    description: "Companies with dominant market share - extended profile in study "
                },
                {
                    id:2,
                    name: "Other players",
                    description: "Companies that are solid contributors - no profile in study"
                },
                {
                    name:'not Selected'
                }
            ],

        }
    },

    mounted() {
        // Ejecuta la función al montar el componente si selectedTopic ya tiene un valor
        if (this.selectedTopic) {
            this.getCompanyListByTopicId();
        }
    },

    computed:{
        selectedTopic() {
          return this.$store.getters.obtainSelectedTopic;
        },
        leadingCompanies(){
            // companiesPerTopic
            let companyWithContracts = JSON.parse(JSON.stringify(this.$store.getters.obtainContractPerCompany));
            // exist companyTopics
            let companyWithCompanyTopics =  companyWithContracts.filter(wc => wc.companyTopics.length> 0);
            // CompanyTopicProfile === leadingCompanies || === 0 
            let companyFilteredTopic = companyWithCompanyTopics.filter(cf => cf.companyTopics.some(ct => ct.topicId === this.selectedTopic.topicId && ct.companyTopicProfile === 0))
            companyFilteredTopic.map(cf => cf.companyTopics = cf.companyTopics.filter(ct => ct.topicId === this.selectedTopic.topicId &&  ct.companyTopicProfile === 0));
    
            companyFilteredTopic.sort((a, b) => a.name.localeCompare(b.name));

            return companyFilteredTopic;
        },
        importantPlayerCompanies(){
            // companiesPerTopic
            let companyWithContracts = JSON.parse(JSON.stringify(this.$store.getters.obtainContractPerCompany));
            // exist companyTopics
            let companyWithCompanyTopics =  companyWithContracts.filter(wc => wc.companyTopics.length > 0 );

            // CompanyTopicProfile === leadingCompanies || === 0 
            let companyFilteredTopic = companyWithCompanyTopics.filter(cf => cf.companyTopics.some(ct => ct.topicId === this.selectedTopic.topicId && ct.companyTopicProfile === 1))
            companyFilteredTopic.map(cf => cf.companyTopics = cf.companyTopics.filter(ct => ct.topicId === this.selectedTopic.topicId &&  ct.companyTopicProfile === 1));

            companyFilteredTopic.sort((a, b )=> a.name.localeCompare(b.name));

            // companyFilteredTopic.map(cf => cf.companyTopics = cf.companyTopics.filter(ct => ct.topicId === this.selectedTopic.topicId &&  ct.companyTopicProfile === 1));
            
            
            return companyFilteredTopic;
        },
    },
    methods:{
        goBack() {
            this.$router.go(-1);
        },

        async getCompanyListByTopicId(){
            try {
                this.loading = true;

                //companiesList
                // await this.$store.dispatch(
                //     'obtainFilteredCompaniesByTopicIdWithTotalsByTopicId',
                //     {
                //         topicId: this.selectedTopic.topicId, 
                //         totals:true , 
                //         byTopic:true
                //     }
                // );

                // //Withcontracts
                await this.$store.dispatch('obtainContractsPerCompany', this.selectedTopic.topicId);

                this.loading = false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false;
            }
        },

        setleadingCompaniesNames(){ // companyNames for exportToDocx with swot
            this.leadingCompaniesNames = []
            let leadingCompaniesFilteredWithSwot = JSON.parse(JSON.stringify(this.leadingCompanies))
            
            leadingCompaniesFilteredWithSwot = leadingCompaniesFilteredWithSwot.filter(lcf => lcf.companyAnalysesItems.length != 0 );

            leadingCompaniesFilteredWithSwot.map(lc => this.leadingCompaniesNames.push(lc.name));
        },
        // FOR TEMPLATES

        goToCompany(companyId){
            window.open(`../../companies/edit/${companyId}`, '_blank')
        },

        showTopicDescription(company, topicId){
            let cT =  company.companyTopics.find(cT => cT.topicId === topicId)
            return cT.description
        },

        sortByDocumentDate(documentCompanies){
            return documentCompanies.sort((a, b) => {
                const dateA = new Date(a.document.documentDate);
                const dateB = new Date(b.document.documentDate);
                return dateB - dateA; // SORT ON DESC 
            });
        },

        convertDateFormatMonthYear(date){
            const dateFormat = new Date(date); 
            // Opciones para formatear la fecha
            const options = { year: 'numeric', month: 'long' };

            // Formatear la fecha
            const formattedDate = dateFormat.toLocaleDateString('en-US', options);
            return formattedDate
        },

        // SWOT
        showSwotList(companyAnalysesItems, swotType){
            let filteredCompanyAnalysesItems = this.filterCompanyAnalysesItems(companyAnalysesItems) 
            let filteredByTypeCompanyAnalysesItems = this.filterCompanyAnalysesItemsByType(filteredCompanyAnalysesItems, swotType)

            filteredByTypeCompanyAnalysesItems.sort((a, b) => a.companyAnalysesItemOrder - b.companyAnalysesItemOrder);
            return filteredByTypeCompanyAnalysesItems
        },
        filterCompanyAnalysesItems(companyAnalysesItems){
            return companyAnalysesItems.filter( cai => cai.topicId === this.selectedTopic.topicId || cai.topicId === null );
        },
        filterCompanyAnalysesItemsByType(companyAnalysesItems, swotType){
            let swotTypeName; 
            switch(swotType){
                case 0:
                    swotTypeName = "Strength"
                    break;
                case 1: 
                    swotTypeName = 'Weakness'
                    break;
                case 2:
                    swotTypeName = 'Opportunity'
                    break;
                case 3:
                    swotTypeName = 'Threat'
                    break;
                default:
                    swotTypeName = null
            }
            return companyAnalysesItems.filter(cai => cai.companyAnalysesType.companyAnalysesTypeName === swotTypeName);
        },

        async exportToWord(){
            this.loadingExportToWord = true;
            
            try {
                // select Element with ID exportCompanyProfile
                const contentElement = this.$el.querySelector('#exportCompanyProfile')
                let  htmlContent;

                if(contentElement){
                    // create Copy Content
                    const clonedContent = contentElement.cloneNode(true);

                    // remove Button 
                    clonedContent.querySelectorAll('#removeTag').forEach(element => {
                        element.remove();
                    });
                   
                    let combinedTablesName =JSON.parse(JSON.stringify(this.leadingCompaniesNames));

                    clonedContent.querySelectorAll('table').forEach((table, tableIndex) => {
                        const label = document.createElement('label');
                        label.textContent = `${combinedTablesName[tableIndex]}`  ; // Cambia el texto según lo necesites
                        table.parentNode.insertBefore(label, table);
                    });

                    htmlContent = clonedContent.outerHTML;
                }
                
                // request Endpoint using formData
                const formData = new FormData();
                formData.append('htmlContent', htmlContent);

                await exportToWordCompanyProfiles(formData , this.selectedTopic.name);
                toast.success('Successfully download');
                this.loadingExportToWord = false;
            } catch (error) {
                this.loadingExportToWord = false;
            }
        },
        cleanCompanyProfile(){
            this.$store.dispatch('cleanContractsPerCompany',);
        }
    },
    watch:{
        async selectedTopic(newValue){
            if(newValue){
                await this.getCompanyListByTopicId();
            }
        },
        leadingCompanies(newVal){
            // when leadingCompanies change Execute setleadingCompaniesNames 
            if(newVal){
                this.setleadingCompaniesNames()
            }
        }
    },
    beforeUnmount() {
        this.cleanCompanyProfile();
    },
}
</script>

<style scoped>

.cp-table{
    width: 100%;
    border-collapse: collapse;
}

.cp-td {
    border: 1px solid black;
    padding: 10px;
    vertical-align: top;
}

.cp-ul {
    margin: 0;
    padding-left: 20px;
}

.text-red{
    color: red;
}
    
</style>