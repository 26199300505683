<template>
    <div v-if="!loading">
        <div class="container">
            <!-- Header -->
            <div class="row">
                <div class="col-12 py-4">
                    <div class="p-fluid container-fluid">
                        <!-- <Button  
                            class="p-button-raised p-button-rounded my-3 p-button-danger" 
                            icon="pi pi-arrow-left"
                            @click="goBack()" 
                        /> -->
                        <h3><strong>{{ topic.name }}</strong> Export Market Forecast Data</h3>
                    </div>
                </div>
            </div>
            <Divider />

            <!-- InputsFields -->
            <div
                style="
                    margin: 2rem 2rem 2rem 2rem;
                    display: grid;
                    grid-template-columns: 25% 72.5%;
                    grid-template-rows: 1fr;
                    gap: 2.5%;
                "
            >
                <!-- FORM 1 -->
                <div 
                    style="
                    "
                >
                    <div class="export-flexColumn">
                        <label> Select Year(s)</label>

                        <div class="export-flexColumn-contractYear">
                            <Calendar 
                                placeholder="Select First Year" 
                                v-model="firstYear" 
                                view="year" 
                                dateFormat="yy"
                                :showIcon="true" 
                                class="mb-3" 
                                style="width: 50%;" 
                            />
                            <Calendar 
                                placeholder="Select Last Year" 
                                v-model="lastYear" 
                                view="year" 
                                dateFormat="yy"
                                :showIcon="true" 
                                class="mb-3" 
                                style="width: 50%;" 
                            />
                        </div>
                    </div>
                    <div class="export-flexColumn">
                        <label> Scenario</label>
                        <Dropdown 
                            v-model="scenario" 
                            :options="topicScenarios" 
                            optionLabel="name"
                            optionValue="scenarioId" 
                            :showClear="true" 
                            placeholder="Scenario" 
                            class="mb-3"
                        />
                    </div>
                    <div class="export-flexColumn" >
                        <div class="export-flexColumn-valueOptionDecimal">
                            <div class="export-flexColumn-valueOptionDecimal-container">
                                <label> Value display option</label>
                                <Dropdown 
                                    v-model="valueDisplayOption" 
                                    :options="valueDisplayOptions" 
                                    placeholder="Select Value" 
                                    class="mb-3"
                                    style="width: 100%;"
                                />
                            </div>
                            <div class="export-flexColumn-valueOptionDecimal-container">
                                <label> Decimals</label>
                                <Dropdown 
                                    v-model="decimalsOption" 
                                    :options="decimalsOptions" 
                                    placeholder="Select Decimals" 
                                    class="mb-3"
                                    style="width: 100%;"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FORM 2 -->
                <div
                    style="
                        width: 100%;
                    "    
                >
                    <Divider align="left">
                        <div class="inline-flex align-items-center">
                            <span class="p-tag">Segments selection:</span>
                        </div>
                    </Divider>
                    <br />

                    <table
                        style="width: 100%;"
                    >   
                        <!-- EXPORT TO WORD SUMMARY -->
                        <tr
                            style="
                                padding: 1rem;
                            "
                        >
                            <!-- Making for to have the segmentations count -->
                            <th
                                v-for="(segmentation , index) in segmentations" 
                                :key="'Summary'+index "
                                style="
                                    border: 1px solid gray;
                                    padding: 1% 0.5% 1% 2.5%;
                                "
                            >
                                <div v-if="index === 0">
                                    <Button
                                        :loading="loadingExportToDocx"
                                        :label="'Summary export to doc(all segments)'"
                                        @click="exportSummaryButton()"
                                    >
                                    <!-- disabled -->
                                    </Button>
                                </div>
                            </th>

                        </tr>

                        <!-- EXPORT TO WORD -->
                        <tr
                            style="
                                padding: 1rem;
                            "
                        >
                            <th 
                                v-for="(segmentation, index) in segmentations" 
                                :key="'segmentation'+index"
                                style="
                                    border: 1px solid gray;
                                    padding: 1% 0.5% 1% 2.5%;
                                "
                            >
                                <Button 
                                    :loading="loadingExportToDocx"
                                    :label="segmentation.name + ' export to Docx'"    
                                    @click="exportButton(segmentation)" 
                                />
                            </th>
                        </tr>

                        <!-- EXPORT JSON FOR IB  -->
                        <tr
                            style="
                                padding: 1rem;
                            "
                        >
                            <th 
                                v-for="(segmentation, index) in segmentations" 
                                :key="segmentation + index"
                                style="
                                    border: 1px solid gray;
                                    padding: 1% 0.5% 1% 2.5%;
                                "
                            >
                                <Button 
                                    :loading="loadingExportToDocx"
                                    :label="segmentation.name + ' export to Json (For IntelligenceBase) ON DEVELOPMENT'"    
                                    @click="exportToJsonForIb(segmentation)"
                                />
                            </th>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <!-- EXPORTS -->
        <!-- Export TO Word  -->
        <div v-if="exportToDocxSuccess">
            <div v-if="exportSingleOutput.length > 0 && exportOutput.length > 0">
                <ProgramOutputExportPreviewPage 
                    :outputVersus="exportOutput" 
                    :outputSingle="exportSingleOutput"
                    :topic="topic" 
                    :valueDisplayOption="valueDisplayOptionOutputExport"
                    :decimalsOption="decimalsOptionOutputExport"
                    :exportLastYear="exportLastYear"
                    :exportFirstYear="exportFirstYear"
                    @setFalseLoadingExportToDocx="setFalseLoadingExportToDocx"
                    :segmentationSelected="this.segmentationSelected"
                    :scenarioName="this.scenarioExportOutput"
                />
            </div>
        </div>
        <!-- Export Summary To word  -->
        <div v-if="exportToDocxSuccessSummary">
            <div v-if="exportSingleOutput.length > 0">
                <ProgramOutputExportSummaryPreview 
                    :outputSingle="exportSingleOutput"
                    :topic="topic"
                    :valueDisplayOption="valueDisplayOptionOutputExport"
                    :decimalsOption="decimalsOptionOutputExport"
                    :exportLastYear="exportLastYear"
                    :exportFirstYear="exportFirstYear"
                    @setFalseLoadingExportToDocx="setFalseLoadingExportToDocx"
                    :scenarioName="this.scenarioExportOutput"
                />
            </div>
            
        </div>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';

import Divider from 'primevue/divider';
// import Checkbox from 'primevue/checkbox';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
// import { exportToExcelOutputValues } from '../../../../topic/services/topic.service';

// import VueAnychart from '../components/VueAnychart.vue';
// import ProgramOutputPreview from '../components/ForecastData/ProgramOutputPreview.vue';
// import ProgramSingleOutputPreview from '../components/ForecastData/ProgramSingleOutputPreview.vue'

// EXPORT COMPONENTS TODO: MAKE ON A PAGE
import ProgramOutputExportPreviewPage from '../components/ExportForecastDataToWord/ProgramOutputExportPreview.page.vue';
import ProgramOutputExportSummaryPreview from '../components/ExportForecastDataToWord/ProgramOutputExportSummaryPreview.vue';

const toast = useToast();

export default {
    name: 'ExportPage',
    components: {
        Button,
        // Card,
        Calendar,
        Dropdown,
        // MultiSelect,
        ProgressSpinner,
        Divider,
        // Checkbox,
        // VueAnychart,
        // ProgramOutputPreview,
        // ProgramSingleOutputPreview,
        // EXPORT 
        ProgramOutputExportPreviewPage,
        ProgramOutputExportSummaryPreview,
    },

    setup: () => ({ v$: useVuelidate() }),

    data() {
        return {
            outputLoading: false,
            outputLoadingSingle: false,
            loadingExportToDocx:false,
            exportToDocxSuccess: false, // validation for ExportTo Word
            exportToDocxSuccessSummary:false,// validation for ExportTo Word Summary
            loading: true,
            loadingPreview: false,

            firstYear: null,
            lastYear: null,
            scenario: null,
            scenarioExportOutput: null,
            // 
            segmentations: [],

            // scenarios: [1, 2, 3, 4, 5],
            segmentationVersus: [],
            selectedSegmentationVersus: [],
            // TODO after finish this task replace this on segmentationVersus
            segmentationVersus2: [],
            selectedSegmentationVersus2: [],
            countOfSegmentations: null,
            // segmentatinoVersus3 
            segmentationVersus3: [],

            //Check
            selectedSegmentationCheck: [],
            selectedCheck: [],
            disableButton: false,


            // Value Display Options
            valueDisplayOptions: [
                'millions',
                'billions',
            ],
            valueDisplayOption: 'millions',
            valueDisplayOptionOutput: null,

            // Decimals
            decimalsOptions: [
                '0.', '.0', '.00', '.000'
            ],
            decimalsOption: '0.',
            decimalsOptionOutput: null,


            // FOR EXPORT
            valueDisplayOptionOutputExport: null,
            decimalsOptionOutputExport: null,
            exportFirstYear: null,
            exportLastYear: null,
            segmentationSelected: null, 
            yearsSelected: null,  
        }
    },

    validations() {
        return {
            firstYear: { required },
            lastYear: { required },
            scenario: { required },
            // selectedSegmentationVersus: { required }
        }
    },

    async mounted() {
        this.getYears();
        await this.getTopicById(this.$route.params.topicId)
        await this.getTopicVersus(this.$route.params.topicId)
        this.topicVersusList()
        //set Base Scenario
        let baseScenario = this.topicScenarios.find(topicScenario => {
            return topicScenario.name === "Base Scenario"
        })

        if (baseScenario) {
            this.scenario = baseScenario.scenarioId
        }
        else {
            this.scenario = this.topicScenarios[0].scenarioId
        }
    },

    computed: {
        topic() {
            return this.$store.getters.obtainTopic;
        },
        topicScenarios() {
            const topic = this.$store.getters.obtainTopic;
            // return topic.scenarios
            return topic.scenarios.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
        },
        topicVersus() {
            return this.$store.getters.obtainTopicVersus;
        },
        output() {
            return this.$store.getters.obtainOutput;
        },
        singleOutput() {
            return this.$store.getters.obtainSingleOutput;
        },

        exportOutput() {
            return this.$store.getters.obtainExportOutput;
        },
        exportSingleOutput() {
            return this.$store.getters.obtainExportSingleOutput;
        },

    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        getYears(){
            // SET YEARS FOR OUTPUT FORM
            const date = new Date(); 
            this.firstYear = date;

            const year = date.getFullYear(); 
            const month = date.getMonth();
            const day = date.getDate();
            const dateLastYear = new Date(year + 8, month, day);
            this.lastYear = dateLastYear
        },

        async getTopicById(topicId) {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopic', topicId);
                this.loading = false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },

        async getTopicVersus(topicId) {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopicVersus', topicId);
                this.loading = false
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },

        topicVersusList() {
            const segmentationVersusArray = []
            const segmentationCount = this.topic.segmentations.length // get CountOF Segmentation for order by array 

            // console.log('topicVersus', this.topicVersus);

            this.topicVersus.map((item, index)=> { 
                if(item.segmentations.length === 2){
                    segmentationVersusArray.push(
                        item.segmentations.reduce(
                            (element1,element2)=>{
                                let segmVersus = { 
                                    name: element1.name + ' x ' + element2.name, 
                                    'index': index ,
                                    'checked': false,
                                }
                                return segmVersus
                            }
                        )
                    )
                }
                else{
                    this.segmentations.push({ 
                        ...item.segmentations[0],
                        'checked':false,
                        'index':index
                    })
                }
            });


            // 2 ORDER THE SEGMENTATIONS VERSUS INTO SEGMENTATIONS
            let finalArray = []; // finalArray
            let containerArray = []; // containerArray 
            let segmentXCount = 0;

            for (let index = 0; index < segmentationVersusArray.length; index++) {
                containerArray.push(
                    {
                        ...(segmentationVersusArray[index]),
                    }
                );
                if (segmentXCount === segmentationCount - 2) { // -1 its for the length of the array; -2 its for the versus & the length of the array;  
                    // ADD containerArry to Final Array and set empty to container Array
                    finalArray.push(containerArray);

                    // clean containersArray 
                    containerArray = [];
                    // setcount to 0; 
                    segmentXCount = 0;
                }
                else {
                    segmentXCount += 1;
                }
            }

            // 1
            // console.log('segmentationVersusArray', segmentationVersusArray);
            // this.segmentationVersus = segmentationVersusArray
            // this.selectedSegmentationVersus = segmentationVersusArray

            // 2
            //TODO after finish this task replace on segmentationversus 
            // console.log('finalArray',finalArray);
            this.transformForTable(finalArray); 
            this.segmentationVersus2 = finalArray;
        },

        transformForTable(array){
            // console.log(array); 
            let a = [];

            for (let index = 0; index < array.length; index++) {
                for (let index2 = 0; index2 < array[index].length; index2++) {
                    if(a[index2]){
                        a[index2].push(array[index][index2])
                    }
                    else{
                        let b = []
                        b.push(array[index][index2])
                        a[index2] = b
                    }
                }
            }
            // console.log('a',a);
            this.segmentationVersus3 = a;
        },

        getScenarioName(scenarioId){
            const scenarioList = JSON.parse(JSON.stringify(this.topicScenarios))
            const scenario = scenarioList.find(item => item.scenarioId === scenarioId);
            return scenario ? scenario.name : 'SceanrioNotFound' 
        },



        //CLean topicVersus
        cleanOutput(){
            this.$store.dispatch('cleanExportOutput');
        },

        // Clean SingleSegmentation 
        cleanSingleOutput(){
            this.$store.dispatch('cleanExportSingleOutput');
        },

        cleanSingleOutputChartsTables(){
            // clean single output
            this.$store.dispatch('cleanExportOutputCharts');
            this.$store.dispatch('cleanExportOutputTables');

        },

        cleanVersusOutputChartsTables(){
            // clean versus ouput
            this.$store.dispatch('cleanExportOutputVersusCharts');
            this.$store.dispatch('cleanExportOutputVersusTables');
        },

        cleanOutputRegionGlobalChart(){
            this.$store.dispatch('cleanExportOutputRegionGlobalChart');
        },

        cleanSuccess(){
            this.exportToDocxSuccess = false;
            this.exportToDocxSuccessSummary = false;
        },

        cleanAll(){
            this.cleanOutput();
            this.cleanSingleOutput();
            this.cleanSingleOutputChartsTables();
            this.cleanVersusOutputChartsTables();
            this.cleanOutputRegionGlobalChart();
            this.cleanSuccess();
            this.scenarioExportOutput = null
        },

        // EXPORT TO WORD SUMMARY 
        async exportSummaryButton(){
            // TODO CLEAN VUEX EXPORT OUTPUT
            this.cleanAll();
            // CLEAN OUTPUTS SUMMARY
            // 
            
            // GET ALL  TOPIC WITH SINGLES SEGMENTATIONS
            // singles topics only have 1 segmentations assigned

            let singleSegmentations = this.topicVersus.filter(tv => tv.segmentations.length === 1 )
            // start loading
            this.loadingExportToDocx = true;

            // set years 
            const startYear = new Date(this.firstYear).getFullYear();
            const endYear = new Date(this.lastYear).getFullYear();

            this.exportFirstYear = startYear
            this.exportLastYear = endYear

            //SET FORM FOR REQUEST
            let formSingle = {
                Topics: singleSegmentations,
                ScenarioId: this.scenario,
                startYear:startYear,
                endYear: endYear,
            }

            // Set scenarioExportOutput
            this.scenarioExportOutput = this.getScenarioName(this.scenario)
            // SET VALUEDISPLAYOPTIONOUTPUT
            this.valueDisplayOptionOutputExport = this.valueDisplayOption
            // SET DECIMALSOPTIONOUTPUT
            this.decimalsOptionOutputExport = this.decimalsOption

            await this.getExportSingleOutput(this.$route.params.topicId, formSingle)
            
            this.exportToDocxSuccessSummary = true; 
        },

        // EXPORT TO WORD 
        async exportButton(segmentation){
            // CLEAN VUEX EXPORTOUPUT 
            // CHANGE TO FUNCTION
            this.cleanAll();

            this.segmentationSelected = null  

            this.exportToDocxSuccess = true;

            // SET  SEGMENTATIONSELECTED and yearsSelected
            this.segmentationSelected = segmentation.name 


            this.loadingExportToDocx = true;
            let singleSegmentationArray = []
            let versusSegmentationArray = []

            const startYear = new Date(this.firstYear).getFullYear()
            const endYear = new Date(this.lastYear).getFullYear()

            this.exportFirstYear = startYear
            this.exportLastYear = endYear

            this.topicVersus.forEach(elements => {
                if(elements.segmentations[0].segmentationId === segmentation.segmentationId ){
                    if(elements.segmentations.length === 1 ){
                        singleSegmentationArray.push(elements);
                    }
                    else{
                        versusSegmentationArray.push(elements);
                    }
                }
            });

            let formSingle = {
                Topics: singleSegmentationArray,
                ScenarioId: this.scenario,
                startYear: startYear,
                endYear: endYear,
            }

            let formVersus = {
                Topics: versusSegmentationArray,
                ScenarioId: this.scenario,
                startYear: startYear,
                endYear: endYear,
            }
            // Set scenarioExportOutput
            this.scenarioExportOutput = this.getScenarioName(this.scenario)
            // SET VALUEDISPLAYOPTIONOUTPUT
            this.valueDisplayOptionOutputExport = this.valueDisplayOption
            // SET DECIMALSOPTIONOUTPUT
            this.decimalsOptionOutputExport = this.decimalsOption

            // await this.getExportOutput(this.$route.params.topicId, formVersus)
            // await this.getExportSingleOutput(this.$route.params.topicId, formSingle)

            let promises = [] 
            promises.push(this.getExportOutput(this.$route.params.topicId, formVersus))
            promises.push(this.getExportSingleOutput(this.$route.params.topicId, formSingle))
            try {
                await Promise.all(promises)
                // this.loadingExportToDocx = false;
                // toast.success('successfully download');
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
            
        },

        // EMIT WHEN DOWNLOADFINISH
        setFalseLoadingExportToDocx(){
            this.loadingExportToDocx = false;
        },

        // Export TO JSON FOR IB
        async exportToJsonForIb(segmentation){
            // CLEAN VUEX EXPORTOUPUT 
            this.cleanAll();

            this.segmentationSelected = null  
            
            // SET  SEGMENTATIONSELECTED and yearsSelected
            this.segmentationSelected = segmentation.name 


            this.loadingExportToDocx = true;
            let singleSegmentationArray = []
            let versusSegmentationArray = []

            const startYear = new Date(this.firstYear).getFullYear()
            const endYear = new Date(this.lastYear).getFullYear()

            this.exportFirstYear = startYear
            this.exportLastYear = endYear

            this.topicVersus.forEach(elements => {
                if(elements.segmentations[0].segmentationId === segmentation.segmentationId ){
                    if(elements.segmentations.length === 1 ){
                        singleSegmentationArray.push(elements);
                    }
                    else{
                        versusSegmentationArray.push(elements);
                    }
                }
            });

            let formSingle = {
                Topics: singleSegmentationArray,
                ScenarioId: this.scenario,
                startYear: startYear,
                endYear: endYear,
            }

            let formVersus = {
                Topics: versusSegmentationArray,
                ScenarioId: this.scenario,
                startYear: startYear,
                endYear: endYear,
            }

            let promises = [] 
            promises.push(this.getExportOutput(this.$route.params.topicId, formVersus))
            promises.push(this.getExportSingleOutput(this.$route.params.topicId, formSingle))

            try {
                await Promise.all(promises)
                this.exportToJSON();
                
                // this.loadingExportToDocx = false;
                // toast.success('successfully download');
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
        },

        exportToJSON(){
            let singleSegmentationToJson = this.exportSingleOutput
            let versusSegmentationToJson = this.exportOutput

            // ITERATE SINGLE ADD TOPICNAME
            singleSegmentationToJson.forEach(obj => {
              obj.topicName = this.topic.name;
            });
            // ITERATE VERSUS ADD TOPICMAME
            versusSegmentationToJson.forEach(obj => {
              obj.topicName = this.topic.name;
            });
            // console.log('singleSegmentationToJson',singleSegmentationToJson);
            // console.log('versusSegmentationToJson',versusSegmentationToJson);

            const jsonDataSingle = JSON.stringify(singleSegmentationToJson, null, 2);
            const jsonDataVersus = JSON.stringify(versusSegmentationToJson, null, 2);

            const singleName = `${this.topic.name}-${this.segmentationSelected}-single`
            const versusName = `${this.topic.name}-${this.segmentationSelected}-versus`


            // console.log(singleName , jsonDataSingle)
            // console.log(versusName , jsonDataVersus)
            this.downloadJSON(singleName , jsonDataSingle)
            this.downloadJSON(versusName , jsonDataVersus)

            this.loadingExportToDocx = false;
        },

        downloadJSON( filename , data){
            const blob = new Blob([data], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        },


        async getExportOutput(topicId, form) {
            try {
                this.outputLoading = true;
                await this.$store.dispatch('obtainExportOutput', { topicId: topicId, form: form });
                this.outputLoading = false
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
        },
        async getExportSingleOutput( topicId , form ){
            try {
                this.outputLoading = true; 
                await this.$store.dispatch('obtainExportSingleOutput',{topicId:topicId, form:form});
                this.outputLoading = false;
            } catch (error) {
                toast.error(error.message);
                this.outputLoading = false;
            }
        },

    },

    watch: {
        'selectedCheck': {
            async handler(newVal) {
                if (newVal.length + this.selectedSegmentationCheck.length > 4) {
                    this.disableButton = true
                }
                else {
                    if (this.disableButton) {
                        this.disableButton = false
                        // console.log('habilitarButton');
                    }
                }
            },
            deep: true,
        },
        'selectedSegmentationCheck': {
            async handler(newVal) {
                if (newVal.length + this.selectedCheck.length > 4) {
                    this.disableButton = true
                }
                else {
                    if (this.disableButton) {
                        this.disableButton = false
                        // console.log('habilitarButton');
                    }
                }
            },
            deep: true,
        },

    },

    beforeUnmount() {
        this.cleanAll();
    }

}

</script>

<style scoped>
.export-flexColumn {
    display: flex;
    flex-direction: column;
}

.export-flexColumn-contractYear {
    display: flex;
    gap: 1rem;
}

.export-flexColumn-valueOptionDecimal {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.export-flexColumn-valueOptionDecimal-container {
    width: 100%;
}
</style>