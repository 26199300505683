<template>
    <!-- table -->
    <div v-if="header.length > 0" style="width: 100%;">
        <table style="
                width: 100%;
                border-collapse: collapse;
            ">
            <thead>
                <tr class="topicOutputTrTextAlign">
                    <th 
                        v-for="(headerItem, index) in header" 
                        :key="index" 
                        style="
                            /* text-align: center; */
                        "
                        class="topicOutputTh"
                        >
                        {{ headerItem }}
                    </th>
                </tr>
                <tr>
                    <br />
                </tr>
            </thead>
            <tbody>
                <template
                    v-for="(region, regionIndex) in segmentationRegionOrder" 
                    :key="'region-'+ regionIndex"
                >
                    <!-- HEADER -->
                    <tr class="topicOutputTrTextAlign">
                        <td
                            v-for="(headerItem, headerItemIndex) in this.headerTotalData[regionIndex]"
                            :key="headerItemIndex"
                            class="topicOutputTh"
                        >
                            <div v-if="headerItemIndex === 0"
                                style="
                                    text-align: left;
                                    font-weight: bold;
                                "
                            >
                                {{ headerItem  }}
                            </div>
                            <div v-else
                                style="
                                    text-align: right;
                                    font-weight: bold;
                                "
                            >
                                {{ formatNumber(headerItem)  }}
                            </div>
                        </td>
                    </tr>

                    <!-- DATA -->
                    <tr
                        v-for="(row, rowIndex) in countryData[regionIndex]"
                        :key="'row-' + regionIndex + '-' + rowIndex"
                        class="topicOutputTrTextAlign"
                    >
                        <td
                            v-for="(item, itemIndex) in row"
                            :key="itemIndex"
                            style="
                            "
                            class="topicOutputTh"
                        >
                            {{ formatNumber( item ) }}
                        </td>
                    </tr>

                    <tr>
                        <br />
                    </tr>


                </template>


                <!-- TOTALFOOTER -->
                <tr class="topicOutputTrTextAlign">
                    <td v-for="(totalItem, totalItemIndex) in this.countryTotalData" :key="totalItemIndex"
                        class="topicOutputTh">
                        <div v-if="totalItemIndex === 0" style="
                                    text-align: left;
                                    font-weight: bold;
                                ">
                            {{ totalItem }}
                        </div>
                        <div v-else style="
                                    text-align: right; 
                                    font-weight: bold;
                                ">
                            {{ totalItem }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div>
            <CopyTableCountryDataThird
                :header="this.header"
                :segmentationRegionOrder="this.segmentationRegionOrder"
                :countryData="this.countryData"
                :headerTotalData="this.headerTotalData"
                :countryTotalData="this.countryTotalData"
            />
        </div>
    </div>

    
</template>


<script>
import CopyTableCountryDataThird from './CopyTableCountryDataThird.vue';

import {toFixDecimate, toFixValueOptionDecimate, formatNumber, formatCagr} from '@/shared/helpers/output/outputFormat';

export default {
    name: 'TableCountryDataThird',
    emits: [],
    props: ['topicSegmentations', 'countriesData', 'valueDisplayOptionOutput', 'decimalsOptionOutput', 'firstYear', 'lastYear'],

    components: {
        CopyTableCountryDataThird,
    },

    data() {
        return {
            loading: false,
            header: [],
            headerTotalData: [],
            countryData: [],
            countryTotalData: [],

            // segmentation Region order
            segmentationRegionOrder: [],
        }
    },

    async mounted() {
        this.convertHeaderTable();
        this.orderRegions();
        this.orderCountries();
        this.createTotalData();
        this.createRegionTotalData();
        this.orderCountriesShare()
        this.orderTotalShare();
    },

    methods: {
        convertHeaderTable() { 
            // [region, pendingTotalFY,pendingTotalLY, pendingTotalSum, Share, CAGR SUM ]

            //COUNTRY, REGION,
            this.header = ['Country'];

            // YEARS,
            const documentProgramValue = this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues;
            // Fy
            const a = this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[0].year;
            // Ly
            const b = this.countriesData[0].regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[documentProgramValue.length - 1].year;

            this.header.push(a);
            this.header.push(b);

            // sum, Share, CAGR FY LY
            let fy = this.firstYear.toString().slice(-2);
            let ly = this.lastYear.toString().slice(-2);

            this.header.push(`∑${fy}-${ly}size`);
            this.header.push('Share');
            this.header.push(`CAGR ${fy}-${ly}`);

        },
        orderRegions() {
            // getSegmentationRegion and Order the data
            this.topicSegmentations.segmentationItems.forEach(element => {
                const segmRegionValues = {
                    name: element.region.name,
                    order: element.order
                }
                this.segmentationRegionOrder.push(segmRegionValues);
            });
        },

        orderCountries() { 
            //table data 
            // prepare countryData for the order countries
            for (let index = 0; index < this.segmentationRegionOrder.length; index++) {
                this.countryData.push([])
            }
            // set countryData to the countriesData
            this.countriesData.forEach(element => {
                let countryArray = []

                // Name
                countryArray.push(element.name)

                // fy
                let fy = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[0].value
                countryArray.push(toFixValueOptionDecimate(fy, this.valueDisplayOptionOutput, this.decimalsOptionOutput ))

                // ly
                const documentProgramValuesIndex = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues.length
                let ly = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.documentProgramValues[documentProgramValuesIndex - 1].value
                countryArray.push(toFixValueOptionDecimate(ly, this.valueDisplayOptionOutput, this.decimalsOptionOutput))

                // Sum
                let sumAll = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.sumAll 
                let sum = toFixValueOptionDecimate(sumAll, this.valueDisplayOptionOutput, this.decimalsOptionOutput ) || toFixValueOptionDecimate(0, this.valueDisplayOptionOutput, this.decimalsOptionOutput)
                countryArray.push(sum)

                // share
                countryArray.push('share pending')

                // CAGR
                let cagrValue = element.regionCountries[0].docProgramTopicSegItems[0].documentProgramTopic.cagr || 0
                let cagrValueFormatted = formatCagr(cagrValue, fy, ly)
                countryArray.push(cagrValueFormatted);

                let order = element.regionCountries[0].region.segmentationItems[0].order
                this.countryData[order].push(countryArray)
            });
        },
        createTotalData() { // bottom Total
            // prepare countryTotalData with the order Countries
            let countryDataArray = JSON.parse(JSON.stringify(this.countryData.flat()));

            this.countryTotalData.push('Total')

            // Sum
            const sumIndexFy = countryDataArray.reduce(
                (accumulator, current) => {
                    return (typeof current[1] === 'string')
                        ? accumulator + parseFloat(current[1])
                        : accumulator + current[1];

                }, 0);

            const sumIndexLy = countryDataArray.reduce(
                (accumulator, current) => {
                    return (typeof current[2] === 'string')
                        ? accumulator + parseFloat(current[2])
                        : accumulator + current[2];
                }, 0);

            const sumIndexTotal = countryDataArray.reduce(
                (accumulator, current) => {
                    return (typeof current[3] === 'string')
                        ? accumulator + parseFloat(current[3])
                        : accumulator + current[3];
                }, 0);

            this.countryTotalData.push(
                toFixDecimate(sumIndexFy, this.decimalsOptionOutput),
                toFixDecimate(sumIndexLy, this.decimalsOptionOutput),
                toFixDecimate(sumIndexTotal, this.decimalsOptionOutput),
                '',
                '',
                // '',
                // '',
            );
        },
        createRegionTotalData(){ //middle Total 
            // prepare headerTotalData with the order Countries
            for (let index = 0; index < this.segmentationRegionOrder.length; index++) {
                this.headerTotalData.push([])
            }
            let countryDataArray = [...this.countryData]

            for (let index = 0; index < countryDataArray.length; index++) {
                if(countryDataArray[index].length > 0){
                    // headerTotalData  
                    // [ region1 , region2 , region3 , region4 ]
                    // [ 
                    //     [region1, region1, fyTotal1, LyTotal1, SumAllTotal1 ] ,
                    //     [region2, region2, fyTotal2, LyTotal2, SumAllTotal2 ] ,
                    //     [region3, region3, fyTotal3, LyTotal3, SumAllTotal3 ] ,
                    //     [region4, region4, fyTotal4, LyTotal4, SumAllTotal4 ] ,
                    // ]

                    this.headerTotalData[index].push(this.segmentationRegionOrder[index].name )


                    // Sum
                    const sumIndexFy = countryDataArray[index].reduce(
                        (accumulator, current) => {
                            return (typeof current[1] === 'string')
                            ? accumulator + parseFloat( current[1] )
                            : accumulator + current[1] ;
                            
                        }, 0);

                    const sumIndexLy = countryDataArray[index].reduce(
                        (accumulator, current) => {
                            return (typeof current[2] === 'string')
                            ? accumulator + parseFloat( current[2] )
                            : accumulator + current[2] ;
                        }, 0);

                    const sumIndexTotal = countryDataArray[index].reduce(
                        (accumulator, current) => {
                            return (typeof current[3] === 'string')
                            ? accumulator + parseFloat( current[3] )
                            : accumulator + current[3];
                        }, 0);

                    this.headerTotalData[index].push( 
                        toFixDecimate(sumIndexFy, this.decimalsOptionOutput), 
                        toFixDecimate(sumIndexLy, this.decimalsOptionOutput),
                        toFixDecimate(sumIndexTotal , this.decimalsOptionOutput),
                        '',
                        '',
                    );
                }
                else{
                    let region = this.segmentationRegionOrder[index].name
                    this.headerTotalData[index].push( region, region,'','','','','')
                }
            }
        },
        orderCountriesShare() {
            this.countryData.forEach(cd => {
                // this.setPercentage(cd)
                let headerTotalDataLength = this.countryTotalData.length
                // ShareTotal
                let maxPercent = this.countryTotalData[headerTotalDataLength - 3]
                cd.forEach(element => {
                    // TotalShare 
                    let sumPart = element[3]
                    const percentage = (sumPart / maxPercent) * 100;
                    element[4] = Math.round(percentage * 10) / 10 + '%';
                })
            });
            
        },

        orderTotalShare(){
            let headerTotalDataLength = this.countryTotalData.length
            
            // ShareTotal
            let maxPercent = this.countryTotalData[headerTotalDataLength - 3] //take bottom total 
            
            this.headerTotalData.forEach(element => {
                // TotalShare 
                let sumPart = element[3]
                const percentage = (sumPart / maxPercent) * 100;
                element[4] = Math.round(percentage * 10) / 10 + '%';
            })
            
            // this.setPercentage(this.headerTotalData)
        },

        formatNumber(data) {
            return formatNumber(data);
        },
    },
    watch: {

    },
}

</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;

    /* padding: 0 1.5% 0 1.5%; */
    padding: 0 0.3% 0 0.3%;

}

.row-zero {
    background-color: #ff7b7b;
    color: #FFFFFF;

}
</style>