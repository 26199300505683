<template>
    <div style="">
        <Button @click="copyToClipBoard()">
            Copy Table
        </Button>

        <table 
            style="
                width: 100%;
                position: absolute;
                top: -9999px;
                left: -9999px;
                border-collapse: collapse;
            "
            :id="'CopyTableCountryDataThird'"
        >
            <thead
                class="CopyTable-CountryData-exportTable-head"
            >
                <tr>
                    <th 
                        v-for="(headerItem, index) in header" 
                        :key="index" 
                        style="
                            /* text-align: center; */
                        " 
                    >
                        {{ headerItem }}
                    </th>
                </tr>
            </thead>
            <tbody
                class="CopyTable-CountryData-exportTable-body"
            >
                <template 
                    v-for="(region, regionIndex) in segmentationRegionOrder" 
                    :key="'region-'+ regionIndex"
                >
                    <!-- HEADER -->
                    <tr>
                        <td 
                            v-for="(headerItem, headerItemIndex) in this.headerTotalData[regionIndex]"
                            :key="headerItemIndex" 
                            class="CopyTable-CountryData-exportTable-body-total"
                        >
                            <div v-if="headerItemIndex === 0" style="
                                    text-align: left;
                                    font-weight: bold;
                                ">
                                {{ headerItem }}
                            </div>
                            <div v-else style="
                                    text-align: right;
                                    font-weight: bold;
                                ">
                                {{ formatNumber(headerItem) }}
                            </div>
                        </td>
                    </tr>

                    <!-- DATA -->
                    <tr 
                        v-for="(row, rowIndex) in countryData[regionIndex]" 
                        :key="'row-' + regionIndex + '-' + rowIndex"
                    >
                        <td 
                            v-for="(item, itemIndex) in row" 
                            :key="itemIndex" 
                            style="
                            "
                        >  
                            <div v-if="itemIndex === 0 " class="CopyTable-CountryData-exportTable-body-rowDesc">
                                {{item}}
                            </div>
                            <div v-else>
                                {{ formatNumber(item) }}
                            </div>
                        </td>
                    </tr>
                </template>


                <!-- TOTALFOOTER -->
                <tr class="topicOutputTrTextAlign">
                    <td v-for="(totalItem, totalItemIndex) in this.countryTotalData" :key="totalItemIndex"
                        class="topicOutputTh">
                        <div v-if="totalItemIndex === 0" style="
                                    text-align: left;
                                    font-weight: bold;
                                ">
                            {{ totalItem }}
                        </div>
                        <div v-else style="
                                    text-align: right; 
                                    font-weight: bold;
                                ">
                            {{ totalItem }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Button from 'primevue/button';
import {formatNumber} from '@/shared/helpers/output/outputFormat';

export default {
    name: 'CopyTableCountryDataThird',
    emits: [],
    props: ['header', 'segmentationRegionOrder', 'countryData', 'headerTotalData', 'countryTotalData'],

    components: {
        Button
    },
    data() {
        return {

        };
    },
    mounted() {

    },

    methods: {
        copyToClipBoard() {
            var range = document.createRange();
            let containerNode = document.getElementById('CopyTableCountryDataThird');
            // containerNode.style.visibility = 'visible';
            // TODO UNCHECK
            containerNode.style.position = 'static'; // move content to the screen

            range.selectNode(containerNode); ///
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            // containerNode.style.visibility = 'hidden';
            // TODO UNCHECK
            containerNode.style.position = 'absolute'; // hide content off the screen
            containerNode.style.top = '-9999px';
            containerNode.style.left = '-9999px';
            alert("data copied");
        },

        formatNumber(data) {
            return formatNumber(data)
        },

    }

}
</script>

<style scoped>

.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.CopyTable-CountryData-exportTable-head th{
    background-color: #3f5f8f;
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    vertical-align: top;
}


.CopyTable-CountryData-exportTable-body td{
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
}

.CopyTable-CountryData-exportTable-body .CopyTable-CountryData-exportTable-body-rowDesc {
    text-align: left;
}
.CopyTable-CountryData-exportTable-body .CopyTable-CountryData-exportTable-body-total{
    font-weight: bold;

}


</style>