<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <CRMList/>
            </div>
        </div>
    </div>
</template>

<script>
import CRMList from './components/CRMList.vue';

export default {
    name:'CriticalRawMaterials',
    components:{
        CRMList,
    },
    methods:{

    }
    
}
</script>

<style>
    
</style>