<template>
    <div>
        <div class="card" >  
            <DataTable
                :value="crmList"
                :loading="loading"
                responsiveLayout="scroll"
                showGridlines
                :paginator="true"

                :rows="rowsPagValue"
                :rowsPerPageOptions="[25, 50]"
                :rowHover="true"
                :first="firstPagValue"
            >
                <template #header>
                    <div>
                        <div style="display: flex; align-items: center;" >
                            <!-- url helper -->
                            <h5 style="margin-bottom: 0;" v-if="selectedTopic" >Critical Raw Materials for:  {{selectedTopic.name}}</h5>
                            <Button
                                class="p-button-raised p-button-rounded my-3 p-button-help mx-3" 
                                icon="pi pi-question-circle" 
                                
                            />
                            <!-- @click="goToHeaderPage('documentsOverview-header')" -->
                        </div>
                    </div>

                    <div
                        class="table-header row gy-3 d-flex flex-row justify-content-between"
                    >
                        <div
                            class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start"
                            >
                            <Button
                                type="button"
                                icon="pi pi-plus"
                                label="Add CRM"
                                class="p-button-outlined"
                                
                            />
                            <!-- @click="openAddDocument()" -->
                        </div>
                    </div>
                </template>

                <!-- RawMaterial -->
                <Column
                    header="Raw Material"
                    
                    style="min-width: 5rem;"
                >
                <!-- sortable -->
                <!-- field="RawMaterial"
                    header="Raw Material" -->
                    <template #body="slotProps">
                        <div v-if="slotProps.data.id === changeToEdit">
                            <InputText v-model="slotProps.data.RawMaterial"></InputText>
                        </div>
                        <div v-else>
                            {{slotProps.data.RawMaterial}}
                        </div>
                    </template>
                </Column>
                <!-- Abb -->
                <Column
                    
                    header="Abb."
                    
                    style="min-width: 5rem;"
                >
                <!-- sortable -->
                    <!-- field="Abbreviation" -->
                    <template #body="slotProps">
                        <div v-if="slotProps.data.id === changeToEdit">
                            <InputText v-model="slotProps.data.Abbreviation"></InputText>
                        </div>
                        <div v-else>
                            {{slotProps.data.Abbreviation}}
                        </div>
                    </template>
                </Column>

                <Column
                    
                    header="Indicative usage"
                    style="min-width: 5rem;"
                >
                <!-- field="indicativeUsage" -->
                    <template #body="slotProps">
                        <div v-if="slotProps.data.id === changeToEdit" style="display: flex; justify-content: space-between;">
                            <InputText v-model="slotProps.data.minRange" style="max-width: 3rem;" /> - <InputText v-model="slotProps.data.maxRange" style="max-width: 3rem;"/>
                        </div>
                        <div v-else>
                            {{slotProps.data.minRange}}-{{slotProps.data.maxRange}}
                        </div>
                    </template>
                </Column>

                <Column
                    field="primaryUses"
                    header="Primary Uses"
                    style="min-width: 30rem;"
                >
                    <template #body="slotProps">
                        <div v-if="slotProps.data.id === changeToEdit">
                            <InputText v-model="slotProps.data.primaryUses" style="width: 100%;"></InputText>
                        </div>
                        <div v-else>
                            {{slotProps.data.primaryUses}}
                        </div>

                    </template>
                </Column>

                <Column
                    style="width: 10rem;"
                >
                    <template #body="slotProps" >
                        <div style="display: flex; justify-content: space-around;" v-if="slotProps.data.id !== changeToEdit">
                            <!-- #body="slotProps" -->
                            <div >
                                <Button
                                    class="p-button-raised p-button-rounded p-button-success"
                                    icon="pi pi-pencil"
                                    @click="editField(slotProps.data)"
                                />
                                <!-- @click="edit(slotProps.data)" -->
                            </div>
                            
                            <div>
                                <Button
                                    icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger"
                                    
                                />
                                <!-- @click="openDeleteMultipleDocumentsDialog()" -->
                            </div>

                        </div>

                        <div style="display: flex; justify-content: space-around;" v-else>
                            <!-- #body="slotProps" -->
                            <div >
                                <Button
                                    class="p-button-raised p-button-rounded p-button-success"
                                    icon="pi pi-save"
                                    @click="editData(slotProps.data)"
                                >
                                </Button>
                                <!-- @click="edit(slotProps.data)" -->
                            </div>
                            
                            <div>
                                <Button
                                    class="p-button-rounded p-button-danger"
                                    icon="pi pi-times"
                                    @click="closeEditField"
                                />
                                <!--  -->
                            </div>

                        </div>
                        

                    </template>

                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';

export default {
    name:'CRMList',
    components:{
        DataTable,
        Column,
        Button,
        InputText,
    },
    data(){
        return{
            loading:true,
            rowsPagValue:25,
            firstPagValue:0,

            crmList:[],
            crmEdit:null,
            changeToEdit:null,
        }
    },
    async mounted(){
        this.setCRMList();
        this.loading = false;
    },
    computed:{
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
    },
    methods:{
        setCRMList(){
            const crmCoded = [
                {
                    id:1,
                    RawMaterial:'Aluminium/Bauxite',
                    Abbreviation:'Al',
                    minRange:100,
                    maxRange:500,
                    primaryUses:'Lightweight structural components',
                },
                {
                    id:2,
                    RawMaterial:'Beryllium',
                    Abbreviation:'Be',
                    minRange:5,
                    maxRange:20,
                    primaryUses:'Radar, Tageting modules',
                },
                {
                    id:3,
                    RawMaterial:'Cobalt',
                    Abbreviation:'Co',
                    minRange:20,
                    maxRange:50,
                    primaryUses:'Batteries, magnets',
                },
            ];
            this.crmList = crmCoded;
        },
        
        editField(data){
            this.changeToEdit = data.id;
        },

        closeEditField(){
            this.changeToEdit =null;
        },

        editData(data){
            // TODO endpoint edit
            const index = this.crmList.findIndex(item => item.id === data.id);
            this.crmList[index] = { ...this.crmList[index], ...data };
            this.closeEditField()
        }
    },

 
}
</script>
<style>
    
</style>