<template>
    <div>
        <h1>ExportSingleOutput</h1>
        <ExportForecastDataToWordSingle 
            :output="outputSingle" 
            :topicName="topic.name"
            :valueDisplayOption="valueDisplayOption" 
            :decimalsOption="decimalsOption" 
        />
    </div>

    <div>
        <h1>ExportOutput</h1>
        <ExportForecastDataToWordVersus 
            :output="outputVersus" 
            :topicName="topic.name"
            :valueDisplayOption="valueDisplayOption" 
            :decimalsOption="decimalsOption"
            @endDataFinish="endDataFinish()" 
        />
    </div>


    <!-- <div v-if="exportOutputTables">
        {{ exportOutputTables }}
    </div> -->

    <!-- <div v-if="exportOutputCharts">
        {{ exportOutputCharts  }}
    </div> -->
    
    <!-- <div v-if="exportOutputVersusTables">
        {{ exportOutputVersusTables }}
    </div> -->

    <!-- <div v-if="exportOutputVersusCharts">
        {{ exportOutputVersusCharts }}
    </div> -->
</template>

<script>
import ExportForecastDataToWordSingle from './ExportForecastDataToWordSingle.vue';
import ExportForecastDataToWordVersus from './ExportForecastDataToWordVersus.vue';
import { exportToWord } from './exportToWord.service';
import { useToast } from 'vue-toastification';


const toast = useToast();

export default {
    name: 'ProgramOutputExportPreview',
    components: {
        ExportForecastDataToWordSingle,
        ExportForecastDataToWordVersus,
    },
    emits: ['setFalseLoadingExportToDocx'],
    props: [
        'outputVersus', 
        'outputSingle', 
        'topic', 
        'valueDisplayOption', 
        'decimalsOption', 
        'exportLastYear', 
        'exportFirstYear', 
        'segmentationSelected',
        'scenarioName'
    ] ,
    data() {
        return {
            dataFinish: false, 

        }
    },
    mounted() {
        // mainTitle
    },
    computed: {
        exportOutputTables(){
            return this.$store.getters.obtainExportOutputTables;
        },
        exportOutputCharts(){
            return this.$store.getters.obtainExportOutputCharts;
        },
        exportOutputVersusTables(){
            return this.$store.getters.obtainExportOutputVersusTables;
        },
        exportOutputVersusCharts(){
            return this.$store.getters.obtainExportOutputVersusCharts;
        },

    },

    methods: {
        endDataFinish(){
            this.dataFinish = true;
        },

        setFalseLoadingExportToDocx(){
            this.$emit("setFalseLoadingExportToDocx");
        },

        async exportToDoc() {
            // SIMPLESEGMENTATION 
            let tables = [];
            this.exportOutputTables.map( (exportOutputTable) => tables.push(exportOutputTable.content) )

            let charts = [];
            this.exportOutputCharts.map( (exportOutputChart) => charts.push(exportOutputChart.content) )

            const preHtml = `<div>`;

            const postHtml = "</div>";

            let bodyContent = '';

            // Main TITLE
            bodyContent += `<h1 style="font-size: 20px; color: red;">${this.topic.name} market to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name}</h1>`;

            // SubTitle1
            // bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1}.1 market by ${this.outputSingle[0].segmentations[0].name} overview</h2> <br />`;
                //REMOVE A.INDEX 
            bodyContent += `<h2 style="font-size:18px;">${this.topic.name} market by ${this.outputSingle[0].segmentations[0].name} overview</h2>`;
            // Chart1
            bodyContent += `<h3 style="font-size:18px;">${this.topic.name} market to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} [US$ ${this.valueDisplayOption}]</h3>`
            bodyContent += `${charts[0]} <br />`
            
            // Table1 
            bodyContent += `<h3 style="font-size:18px;">${this.topic.name} market to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} [US$ ${this.valueDisplayOption}]</h3>`
            bodyContent += `${tables[0]} <br />`
            
            // Chart2
            bodyContent += `<h3 style="font-size:18px;">${this.topic.name} market to ${this.exportLastYear} by ${this.outputSingle[0].segmentations[0].name} %</h3>`
            bodyContent += `${charts[1]} <br />`

            // VERSUS SEGMENTATION
            // Sort versus charts and tables
            // Tables 
            let sortedBySegmentVersusTables = []; 
            JSON.parse(JSON.stringify(this.exportOutputVersusTables)).forEach(item => {
                if(!sortedBySegmentVersusTables[item.order]){
                    sortedBySegmentVersusTables[item.order] = [];
                }
                sortedBySegmentVersusTables[item.order].push(item);
            });

            // // charts
            let sortedBySegmentVersusCharts = []; 
            JSON.parse(JSON.stringify(this.exportOutputVersusCharts)).forEach(item => {
                if(!sortedBySegmentVersusCharts[item.order]){
                    sortedBySegmentVersusCharts[item.order] = [];
                }
                sortedBySegmentVersusCharts[item.order].push(item);
            })

            for (let [segmIndex, segm] of sortedBySegmentVersusTables.entries()) {
                // ITERATE ALL SEGM VERSUS  TABLES 
                let tablesVersus = [];
                segm.map( (exportOutputTable) => tablesVersus.push(exportOutputTable.content) );

                //ITERATE ALL SEGM VERSUS CHARTS
                let chartsVersus = [];
                sortedBySegmentVersusCharts[segmIndex].map( (exportOutputChart) => chartsVersus.push(exportOutputChart.content) );

                // ORDER SEGM VERSUS CHARTS 
                // THE SEG VERSUSCHART ONLY HAVE A LIST WITH ALL CHARTS 
                // SO IT GROUP BY PAIR CAUSE THERE 2 CHARTS ON ONE SEGMVERSUS
                const dataUrlsVersus = [];

                for (let i = 0; i < chartsVersus.length; i += 2) {
                    dataUrlsVersus.push([chartsVersus[i], chartsVersus[i + 1]]);
                }

                // for (let chartVersus of chartsVersus) {
                    // dataUrlsVersus.push(chartVersus);
                // }

                // SubTitle
                // bodyContent += `<h2 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1} ${this.topic.name} market ${segm[0].segmentation1} by ${segm[0].segmentation2} overview </h2> <br />`;
                    // REMOVE A.index 
                bodyContent += `<h2 style="font-size:18px;">${this.topic.name} market ${segm[0].segmentation1} by ${segm[0].segmentation2} overview </h2>`;

                segm.forEach( (segmRow,segmRowIndex ) => {
                    // Segm title
                    // bodyContent += `<h3 style="font-size:18px;">A.${this.outputSingle[0].segmentations[0].order + 1 + segmIndex + 1}.${segmIndex + 1} ${segmRow.segmentationItem} market by ${segm[0].segmentation2} overview</h3> <br />`;
                        // REMOVE A.index 
                    bodyContent += `<h3 style="font-size:18px;">${segmRow.segmentationItem} market by ${segm[0].segmentation2} overview</h3>`;

                    // h3 
                    bodyContent += `<h3 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} market to ${this.exportLastYear} by ${segm[0].segmentation2} [US$ ${this.valueDisplayOption}]</h3>`;
                    // table
                    bodyContent += `${segmRow.content} <br />`;

                    // h4 
                    bodyContent += `<h3 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} market to ${this.exportLastYear} by ${segm[0].segmentation2}</h3>`;
                    // chart
                    // bodyContent += `<img src="${dataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
                    bodyContent += `${dataUrlsVersus[segmRowIndex][0]} <br />`;

                    // console.log('segm',segm);
                    // console.log('segmRow',segmRow);
                    // console.log('segmRow',segmRowIndex);
                    
                    // h4 
                    bodyContent += `<h3 style="font-size:18px;">${segmRow.segmentationItem} - ${this.topic.name} market to ${this.exportLastYear} by ${segm[0].segmentation2}</h3>`;
                    // chart
                    // bodyContent += `<img src="${dataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
                    bodyContent += `${dataUrlsVersus[segmRowIndex][1]} <br />`;

                    // h4 
                    // bodyContent += `<h4 style="font-size:18px;">${segmRow.segmentationItem}  ${this.topic.name} Market Forecast to ${this.exportLastYear} by ${segm[0].segmentation2} % </h4> <br />`;
                    // chart TODO ADD NEW CHART ON MARKET FORECAST, THEN CREATE NEW VUEX TO ADD THE NEW CHARTS
                    // bodyContent += `<img src="${pngDataUrlsVersus[segmRowIndex]}" style="max-width:100%; height:auto;" /><br>`;
                });
            }
            const fullHtml = preHtml + bodyContent + postHtml
            // console.log(fullHtml);

            try {
                // create an object FormData to send backend 
                const formData = new FormData();
                formData.append('htmlContent', fullHtml);

                // Send content to  backend to convetion
                // const response = await axios.post('http://localhost:5011/api/conversion/convert', formData, {
                //     responseType: 'blob'  // Esperar un archivo binario de respuesta
                // });

                // const response = await exportToWord(formData);
                // topicName, segmentation, years
                await exportToWord(formData, this.topic.name, this.segmentationSelected,this.scenarioName );
                // console.log('successResponse',response);
                toast.success('Successfully download');
                // create URL for download Docx 
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', 'documento.docx'); // Nombre del archivo
                // document.body.appendChild(link);
                // link.click();
            } catch (error) {
                console.error('Error Convertion:', error);
                toast.error('Error DOCX convertion');
            }
            finally{
                this.setFalseLoadingExportToDocx();
            }
        },

    },
    watch: {
        // 'exportOutputTables': {
        //     async handler(newVal) {
        //         if (newVal) {
        //             if(this.exportOutputCharts.length === 2 && this.exportOutputTables.length === 1){
        //                 // await this.exportToDoc();
        //             }
        //         }
        //     },
        //     deep: true,
        // },

        dataFinish:{
            async handler(newVal) {
                if (newVal) {
                    await this.exportToDoc();
                }
            },
        } 
    },
    beforeUnmount() {
        // console.log('dismounted');
    },
}

</script>

<style></style>