<template>
    <div style="
        position: relative;
        /* top: 110%; */
        /* left: -0%; */
    ">
        <Button @click="copyToClipBoard()">
            Copy Table
        </Button>

        <table 
            style="
                width: 100%; 
                position: absolute;
                top: -9999px;
                left: -9999px;
                border-collapse: collapse;
            "     
            :id="'CopyTableCountryDataId'"
        >
            <!-- header table -->
            <thead
                class="CopyTable-CountryData-exportTable-head" 
            >
                <tr>
                    <th
                        v-for="(header, index) in this.header"
                        :key="index"
                    >
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <!-- data -->

            <tbody
                class="CopyTable-CountryData-exportTable-body"
            >
                <tr
                    v-for="(row, rowIndex) in this.countryData" 
                    :key="rowIndex"
                >
                    <td
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                    >
                        <div v-if="cellIndex === 0 " class="CopyTable-CountryData-exportTable-body-rowDesc">
                            {{ cell }}
                        </div>
                        <div v-else>
                            {{ formatNumber(cell) }}
                        </div>
                    </td>
                </tr>

                <!-- total -->
                <tr>
                    <td
                        v-for="(total, totalIndex) in this.totalData"
                        :key="totalIndex"
                    >
                        
                        <div 
                            v-if="totalIndex === 0" 
                            style="
                                text-align: left;
                                font-weight: bold;
                            "
                        >
                            {{ total }}
                        </div>
                        <div v-else 
                            style="
                                text-align: right; 
                                font-weight: bold;
                            "
                        >
                            {{ formatNumber(total)  }}
                        </div>

                    </td>
                </tr>

            </tbody>

        </table>
    </div>
</template>

<script>
import Button from 'primevue/button';
import {formatNumber} from '@/shared/helpers/output/outputFormat';

export default {
    name: 'CopyTableCountryData',
    emits:[],
    props:['header', 'countryData','totalData'],


    components: {
        Button
    },

    data(){
        return {
            
        };
    },
     methods: {
        isRowAllZero(){
            // used for validation
            let rowData = JSON.parse(JSON.stringify(this.countryData))
            rowData.forEach(cd => { cd.shift(); cd.shift();cd.shift();} );

            return rowData.map(r => r.every(cell => cell === 0 || cell === '0.0' || cell === '0.00' || cell === '0.000' || cell === '-' ));
        },
        copyToClipBoard(){
            var range = document.createRange();
            let containerNode = document.getElementById('CopyTableCountryDataId'); 
            // containerNode.style.visibility = 'visible';
            // TODO UNCHECK
            containerNode.style.position = 'static'; // move content to the screen

            range.selectNode(containerNode); ///
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
            // containerNode.style.visibility = 'hidden';
            // TODO UNCHECK
            containerNode.style.position = 'absolute'; // hide content off the screen
            containerNode.style.top= '-9999px';
            containerNode.style.left= '-9999px';
            alert("data copied");
        },
        formatNumber(data){
            return formatNumber(data)
            // if(data){
            //     // split int and decimals of number
            //     let [int, decimal] = data.toString().split('.');

            //     // format int with , 
            //     int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            //     return decimal ? `${int}.${decimal}` : int
            // }
            // else{
            //     return data
            // }
        },
    },

}

</script>

<style scoped>

.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.CopyTable-CountryData-exportTable-head th{
    background-color: #3f5f8f;
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    vertical-align: top;
}


.CopyTable-CountryData-exportTable-body td{
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 11px;
    text-align: right;
}

.CopyTable-CountryData-exportTable-body .CopyTable-CountryData-exportTable-body-rowDesc {
    text-align: left;
}
.CopyTable-CountryData-exportTable-body .CopyTable-CountryData-exportTable-body-total{
    font-weight: bold;

}

.row-zero{
    background-color:#ff7b7b ;
    color: #FFFFFF;

}

</style>
