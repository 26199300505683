<template>
    <div class="container-datas" style="font-size: 1rem; margin-top: 2rem;">
        <!-- <strong>{{ valueDisplayOption }}</strong> -->
        <!-- <strong>{{ decimalsOption }}</strong> -->
        <div v-if="toChartSegmItems.length > 0">
            <div v-for="(count, index1 ) in toChartSegmItems" :key="index1" style="
                display: flex;
                flex-direction: column;
                gap: 2rem;
                width: 100%;
            ">
                <!-- Versus Title -->
                <h4> <strong>{{ topicName }}</strong> Market Forecast to {{ toChartYears[0][toChartYears[0].length -1] }} {{ this.SegmentationVersusTitles[index1][0][0] }} US$ {{this.valueDisplayOption}}  </h4>
                <div v-for="(charts, index) in count" :key="index" style="
                        width: 100%;
                        height: 150%;
                        margin-bottom: 5rem;
                    ">
                    <div style="
                        display: grid; 
                        grid-template-columns: 1fr; 
                        position: relative;
                        gap: 8rem;
                        margin-bottom: 5rem;
                        padding: 0rem 2.5rem 0rem 2.5rem;
                    ">
                        <!-- Table -->
                        <div style="
                                width: 100%;
                                position: relative;
                            ">
                            <table style="width: 100%;">
                                <!-- Segment SubTitle -->
                                <!-- <tr>
                                    <td>
                                        <strong>{{ this.segmentationTitles[index][0] }}</strong>
                                    </td>
                                </tr> -->
                                <!-- TableTitle -->
                                <tr class="topicOutputTrTextAlign">
                                    <th v-for="(header, index2 ) in this.headerTable[0]" :key="index2" class="topicOutputTh">
                                        <div v-if="index2 === 0">
                                            {{ this.segmentationTitles[index1][index] }} US$ {{this.valueDisplayOption}}
                                            <!-- <strong>{{ this.segmentationTitles[index] }}</strong> -->
                                        </div>
                                        <div v-html="header" style="display: flex; justify-content: flex-end; align-items: center;"></div>
                                            <!-- {{ header }} -->
                                    </th>
                                </tr>
                                <!-- Data -->
                                <tr v-for="(row, rowIndex) in this.segmentationItemValues[index1][index]"
                                    :key="rowIndex" class="topicOutputTrTextAlign">
                                    <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="topicOutputTh" >
                                        {{ formatNumber(cell) }}
                                        <!-- {{cell}} -->
                                    </td>
                                </tr>
                                <!-- Total -->
                                <tr 
                                    v-for="(total, totalIndex) in this.totals[index1][index]" 
                                    :key="totalIndex"
                                    class="topicOutputTrTextAlign"
                                >
                                    <td v-for="(cell, cellIndex) in total" :key="cellIndex" class="topicOutputTh">
                                        <div v-if="cellIndex === 0" style=" text-align: right; font-weight: bold; ">
                                            {{ formatNumber(cell) }}
                                        </div>
                                        <div v-else style="font-weight: bold; ">
                                            {{ formatNumber(cell) }}
                                        </div>
                                        <!-- {{ cell }} -->
                                    </td>
                                </tr>
                            </table>

                            <CopyTable 
                                :index="index" 
                                :index1="index1"
                                :SegmentationVersusTitles="this.SegmentationVersusTitles"
                                :segmentationTitles="this.segmentationTitles" 
                                :headerTable="this.headerTable"
                                :segmentationItemValues="this.segmentationItemValues" 
                                :totals="totals"
                                :valueDisplayOption="valueDisplayOption"
                            />
                        </div>
                        <!-- st01 -->
                        <div style="
                            /* display: grid; */
                            /* grid-template-columns: 1fr 1fr; */
                        ">
                            <div style="
                                    width: 100%;
                                    height: 500px;
                                "
                                v-if="loadingStep >= 1"
                            >
                            <!-- {{ this.SegmentationVersusTitles[index1][0] }} -->
                            <!-- {{ toChartSegmItems[index1][index]  }} -->
                                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px;font-weight:bold;">
                                        {{this.segmentationTitles[index1][index]}} Market Forecast to {{ toChartYears[0][toChartYears[0].length -1 ]}} by {{this.segmentation2[index1]}}
                                    </span>
                                    <span style="color:#929292; font-size: 12px;">
                                        Market Forecast {{toChartYears[0][0]}} - {{toChartYears[0][toChartYears[0].length - 1]}}
                                    </span>
                                </div>
                            
                                <VueAnychartVersus 
                                    :years="toChartYears[0]" 
                                    :data="toChartData[index1][index]"
                                    :total="totals[index1][index][0]"
                                    :segmentationItems="toChartSegmItems[index1][index]" 
                                    :output="output"
                                    :segmentationVersusTitles="SegmentationVersusTitles[index1][0]"
                                    
                                    :execStep="(index1 === 0 && index === 0)? true :false "
                                    @next-step="nextStep"
                                    :topicName="topicName"
                                    :valueDisplayOption="valueDisplayOption"
                                    :segmentationName1="segmentation1[index1]"
                                    :segmentationName2="segmentation2[index1]"
                                    :segmentation1ItemsName="segmentationTitles[index1][index]"
                                    :segmentationItemsFullName="segmentationItemsFullName[index1]"
                                ></VueAnychartVersus>
                            </div>
                            <div v-else style="display: flex; justify-content: center; align-items: center;">
                                <ProgressSpinner 
                                />
                            </div>

                            
                        </div>
                        <!-- st02 versus  -->
                        <div
                            style="
                                display: grid;
                                grid-template-columns: 100%;
                            "
                        >
                        
                            <div
                                style="width: 100%;"
                                v-if="loadingStep >= 2"
                            >
                                <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 1rem;">
                                    <span style="font-size: 16px; font-weight: bold;">{{ this.segmentationTitles[index1][index] }} - {{topicName}} Market Forecast to {{this.toFigureVersus2ChartLastYear}} by {{this.segmentation2[index1]}}</span>
                                </div> 

                                <div style="width: 100%; height: 500px;">
                                    <div
                                        style="height: 100%; width: 100%; display: flex;"
                                        v-if="compareFYFL( toChartData[index1][index][0], toChartData[index1][index][toChartData[index1][index].length -1] )"
                                    >
                                        <div style="width: 55%;">
                                            <VueAnychartVersusFigure2
                                                :data="this.toFigureVersus2ChartDataYear[index1][index]"
                                                :year="this.toFigureVersus2ChartFirstYear"
                                                :firstYear="this.toFigureVersus2ChartFirstYear"
                                                :lastYear="this.toFigureVersus2ChartLastYear"
                                                :topicName="this.topicName"
                                                :segmentationName="this.segmentationTitles[index1][index]"
                                                :valueDisplayOption="valueDisplayOption"
                                                :FYValues="toChartData[index1][index][0]"
                                                :execStep="(index1 === 0 && index === 0)? true :false "
                                                @next-step="nextStep"
                                                :yearIndex="1"
                                                :decimalsOption="decimalsOption"
                                                :segmentationItemsFullName="segmentationItemsFullName[index1]"
                                            />
                                        </div>
                                        <div style="width: 45%;">
                                            <VueAnychartVersusFigure2
                                                :data="this.toFigureVersus2ChartDataYear[index1][index]"
                                                :year="this.toFigureVersus2ChartFirstYear"
                                                :firstYear="this.toFigureVersus2ChartFirstYear"
                                                :lastYear="this.toFigureVersus2ChartLastYear"
                                                :topicName="this.topicName"
                                                :segmentationName="this.segmentationTitles[index1][index]"
                                                :valueDisplayOption="valueDisplayOption"
                                                :LYValues="toChartData[index1][index][toChartData[index1][index].length -1]"
                                                @next-step="nextStep"
                                                :yearIndex="2"
                                                :decimalsOption="decimalsOption"
                                                :segmentationItemsFullName="segmentationItemsFullName[index1]"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        style="height: 100%; width: 100%;display: flex;"
                                        v-else
                                    >
                                        <div style="width: 45%;">
                                            <VueAnychartVersusFigure2
                                                :data="this.toFigureVersus2ChartDataYear[index1][index]"    
                                                :year="this.toFigureVersus2ChartFirstYear"
                                                :firstYear="this.toFigureVersus2ChartFirstYear"
                                                :lastYear="this.toFigureVersus2ChartLastYear"
                                                :topicName="this.topicName"
                                                :segmentationName="this.segmentationTitles[index1][index]"
                                                :valueDisplayOption="valueDisplayOption"
                                                :FYValues="toChartData[index1][index][0]"
                                                :execStep="(index1 === 0 && index === 0)? true :false "
                                                @next-step="nextStep"
                                                :yearIndex="1"
                                                :decimalsOption="decimalsOption"
                                                :segmentationItemsFullName="segmentationItemsFullName[index1]"
                                            />
                                        </div>
                                        <div style="width: 55%;">
                                            <VueAnychartVersusFigure2
                                                :data="this.toFigureVersus2ChartDataYear[index1][index]"
                                                :year="this.toFigureVersus2ChartFirstYear"
                                                :firstYear="this.toFigureVersus2ChartFirstYear"
                                                :lastYear="this.toFigureVersus2ChartLastYear"
                                                :topicName="this.topicName"
                                                :segmentationName="this.segmentationTitles[index1][index]"
                                                :valueDisplayOption="valueDisplayOption"
                                                :LYValues="toChartData[index1][index][toChartData[index1][index].length -1]"
                                                @next-step="nextStep"
                                                :yearIndex="2"
                                                :decimalsOption="decimalsOption"
                                                :segmentationItemsFullName="segmentationItemsFullName[index1]"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else style="display: flex; justify-content: center; align-items: center;">
                                <ProgressSpinner/>
                            </div>
                        </div>

                        <!-- Divider -->
                        <Divider></Divider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueAnychartVersus from './VueAnychartVersus.vue';
import VueAnychartVersusFigure2 from './VueAnychartVersusFigure2.vue';
import CopyTable from './CopyTable.vue';
import ProgressSpinner from 'primevue/progressspinner';
import Divider from 'primevue/divider';

// shared Functions
import {
    toFixDecimate, 
    toFixValueOptionDecimate, 
    formatNumber,
    formatCagr 
} from '@/shared/helpers/output/outputFormat';


export default {
    name: 'programOutputPreview',
    components: {
        VueAnychartVersus,
        VueAnychartVersusFigure2,
        CopyTable,
        ProgressSpinner,
        Divider,
    },
    emits: [],
    // TODO, CHECK IF PROPS ARE TRUE SO THIS PREVENT DOUBLE MOUNTING  
    props: ['output','topicName','valueDisplayOption','decimalsOption'],
    data() {
        return {
            // outputViews for tables
            SegmentationVersusTitles: [],
            segmentationTitles: [],
            headerTable: [],
            segmentationItemValues: [],
            segmentation1:[],
            segmentation2:[],
            segmentation1Items:[],

            // segmentationItemsFullname
            segmentationItemsFullName: [],

            // output for charts
            toChartYears: [],
            toChartData: [],
            toChartSegmItems: [],

            totals: [],

            // output For Figure3 Charts
            toFigureVersus2ChartDataYear: [],
            toFigureVersus2ChartFirstYear: [],
            toFigureVersus2ChartLastYear: [],

            toFigureVersus2TotalFirstYear:null,
            toFigureVersus2TotalLastYear:null,

            // Sync loading 
            loadingStep:0, 
            loadingSteps: 2, // length of chart on the page
        }
    },

    mounted() {
        // output
        this.assignOutput();
        this.assignSegmItemFullNameOrDescription(); 
        this.assignOutputToChart();
        this.getTotalRow();

        // figure3
        this.assignOutputToFigureVersus2Chart();

        // subimos a 1
        this.nextStep();
    },

    methods: {
        nextStep(){
            if(this.loadingStep < this.loadingSteps){
                setTimeout(() => {
                    this.loadingStep++
                }, 3000);
            }
        },

        assignOutput() {
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028]
            // let years = ['US$M']
            let years = ['']
            this.output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))

            // ADD TO years [SUM , CAGR] TO SHOW
            //  SHOW ARRAY ['US$M',2022,2023,2024,2025,2026,2027,2028,SUM, CAGR]

            const fyString = years[1].toString()
            const lyString = years[years.length - 1].toString()

            let sumHeader = '∑'+fyString.slice(-2) +'-'+ lyString.slice(-2);
            years.push(sumHeader);


            let cagr = 'CAGR <br />'+fyString.slice(-2) +'-'+ lyString.slice(-2); 
            years.push(cagr);

            this.headerTable.push(years);

            let segItemNameGroupLast = []

            for (let index = 0; index < this.output.length; index++) {
                let titles = [];

                // this.SegmentationVersusTitles SHOW VERSUS TITLE ['SEGMENTATION X SEGMENTATION']
                const segmentationName = this.output[index].segmentations[0].name
                const segmentationName2 = this.output[index].segmentations[1].name

                titles.push([segmentationName + ' - ' + segmentationName2]);
                this.SegmentationVersusTitles.push(titles);

                this.segmentation1.push(segmentationName)
                this.segmentation2.push(segmentationName2)

                // versusValueFInal

                const versusValueFinal = []
                let SegmItemNameGroup = [] 
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    // this.segmentationTitles SHOW THE SUBTITLE ['SEGMENTATION']
                    let segmItemName

                    if(item.regionId != null){
                        segmItemName = item.region.name
                    }
                    else{
                        if(item.description != null){
                            segmItemName = item.description
                        }
                        else{
                            segmItemName = item.name
                        }
                    }

                    SegmItemNameGroup.push(segmItemName)
                    // versusValueFinal IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                    // ['segmentationItem', 1,2,3,4,5,6,7]

                    const versusValue = []
                    item.inverseParent.map(item2 => {
                        const versusValue2 = []
                        // change RegionId to regionName
                        if (item2.regionId != null) { //for !region
                            if(item2.name){
                                versusValue2.push(item2.name)
                            }
                            else{
                                versusValue2.push(item2.region.name)
                            }
                        }
                        else {
                            versusValue2.push(item2.name)
                        }
                        let FYValue = 0;
                        let LYValue = 0;

                        item2.documentProgramTopic.documentProgramValues.map(
                            (item3 , item3Index) => {
                                versusValue2.push(toFixValueOptionDecimate(item3.value, this.valueDisplayOption, this.decimalsOption))

                                if(item3Index === 0){
                                    FYValue = item3.value;
                                }
                                
                                if(item3Index === item2.documentProgramTopic.documentProgramValues.length -1 ){
                                    LYValue = item3.value;
                                }

                            }
                        )
                        // SUM 
                        // get the sum on front
                        let sumArray = [...versusValue2]
                        sumArray.shift()
                        let sum = toFixDecimate(sumArray.reduce((acc, val) => acc +parseFloat(val),0), this.decimalsOption); 

                        // sum from backend  
                        // let sum = toFixValueOptionDecimate(item2.documentProgramTopic.sumAll, this.valueDisplayOption, this.decimalsOption)
                        versusValue2.push(sum)


                        // CAGR 
                        // let cagr = (item2.documentProgramTopic.cagr) ? item2.documentProgramTopic.cagr.toFixed(1) + '%': 0 + '%' 
                        let cagr  = formatCagr(item2.documentProgramTopic.cagr, FYValue, LYValue); 
    
                        versusValue2.push(cagr) // CAGR

                        versusValue.push(versusValue2)
                    })
                    versusValueFinal.push(versusValue)
                })
                segItemNameGroupLast.push(SegmItemNameGroup);
                // this.segmentationItemValues IS THE ARRAY THAT SHOW SEGMVERSUSTITLE AND THE MARKETDATA
                this.segmentationItemValues.push(versusValueFinal)
            }

            this.segmentationTitles = segItemNameGroupLast
        },

        assignSegmItemFullNameOrDescription(){
            // set SegmentationItems Description or full Name
            
            for(let index = 0; index < this.output.length; index ++){
                let segmItemFullNameArray = [];
                this.output[index].segmentations[0].segmentationItems[0].inverseParent.map(
                    item => {
                        let segmItemName
                        if(item.description){
                            segmItemName = item.description
                        }
                        else{
                            if(item.regionId != null){
                                segmItemName = item.region.name
                            }
                            else{
                                segmItemName = item.name
                            }
                        }
                        segmItemFullNameArray.push(segmItemName)
                    }
                )
                this.segmentationItemsFullName.push(segmItemFullNameArray)
            }
        },

        assignOutputToChart() {
            this.toChartData = [];
            this.toChartYears = [];

            // outputYears SHOW ARRAY OF YEARS ['2024','2025','2026']
            const outputViews = []
            let years = []
            this.output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.map(item => years.push(item.year))
            outputViews.push(years)
            this.toChartYears = outputViews;


            // outputData SHOW ARRAY OF ARRAY FOR SHOWING TO CHART
            // 
            // [
            //     [[1,2,3],[4,5,6]],
            //     [[7,8,9],[10,11,12]],
            // ]
            // EXPLAIN
            // [
            //     segment1
            //     [
            //         north America[0,0,0],  europe [0,0,0]
            //     ] , 
            //     segment2
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ] , 
            //     segment3
            //     [
            //         north America[0,0,0], europe[0,0,0]
            //     ]
            // ]

            let outputData = []
            for (let index = 0; index < this.output.length; index++) {
                const versusValueFinal = []
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    const versusValue = []

                    item.inverseParent.map(item2 => {
                        const versusValue2 = []
                        item2.documentProgramTopic.documentProgramValues.map(
                            item3 => {
                                versusValue2.push(toFixValueOptionDecimate(item3.value,this.valueDisplayOption, this.decimalsOption))
                            }
                        )
                        versusValue.push(versusValue2)
                    })
                    versusValueFinal.push(versusValue)
                })
                outputData.push(versusValueFinal)
            }
            // outputDataFinalle SHOW ARRAY BY YEARS COUNT SHOW VALUE BY COLUMN 
            // [
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ],
            //     [
            //         [1,2],  YEAR 2024
            //         [3,4],  YEAR 2025
            //         [segmentationItem1, segmentationItem2]
            //     ]
            // ]

            // set the data to years 
            let outputDataFinalle = []

            outputData.map((output) => {
                let bData = []
                output.map((item) => {
                    let outputArray = []
                    outputViews[0].map((year, index) => {
                        let aData = []
                        item.map(data => {
                            aData.push(data[index])
                        })
                        outputArray.push(aData)
                    })
                    bData.push(outputArray);
                })
                outputDataFinalle.push(bData);
            })

            this.toChartData = outputDataFinalle

            //SegmItemsRival
            // SHOW THE SEGMENTATIONITEMS FOR LEGACY ['SEGMENTATION1','SEGMENTATION2','SEGMENTATION3']

            let Array = [];
            for (let index = 0; index < this.output.length; index++) {
                let SegmItemsRivalNameArray0 = [];
                this.output[index].segmentations[0].segmentationItems.map(item => {
                    const SegmItemsRivalNameArray = [];
                    item.inverseParent.map(item2 => {
                        let versusValue2
                        // change RegionId to regionName
                        if (item2.regionId != null) { //for !region
                            versusValue2 = item2.region.name
                        }
                        else {
                            versusValue2 = item2.name
                        }
                        SegmItemsRivalNameArray.push(versusValue2)
                    })
                    SegmItemsRivalNameArray0.push(SegmItemsRivalNameArray);
                })
                Array.push(SegmItemsRivalNameArray0);
            }
            this.toChartSegmItems = Array
        },

        cleanData() {
            this.toChartYears = []
            this.toChartData = []
            this.toChartSegmItems = []

            // outputViews
            this.headerTable = []
            this.SegmentationVersusTitles = []
            this.segmentationTitles = []
            this.segmentationItemValues = []

            // output For Figure3 Charts
            this.toFigureVersus2ChartDataYear = []
            this.toFigureVersus2ChartFirstYear = []
            this.toFigureVersus2ChartLastYear= []

            this.toFigureVersus2TotalFirstYear = null
            this.toFigureVersus2TotalLastYear = null

            //Totals
            this.totals = []
        },
        getTotalRow() {
            // CLONE DEEP ARRAY 
            let segmentationItemValuesClone = JSON.parse(JSON.stringify(this.segmentationItemValues));

            //GET TOTAL
            segmentationItemValuesClone.map(siv => {
                let a = []
                siv.map((segmItem) => {
                    let b = []
                    segmItem.map((si) => {
                        if (b.length === 0) {
                            let totArray = []
                            si.map((s, index) => {
                                if (index === 0) {
                                    totArray.push("Total")
                                }
                                else {
                                    if(index !== si.length-1){
                                        if (typeof s !== 'number') {
                                            let parseToNumber = Number(s);
                                            totArray.push(parseToNumber)
                                        }
                                        else {
                                            totArray.push(s)
                                        }
                                    }
                                }
                            })
                            b.push(totArray)
                        }
                        else {
                            si.map((s, index2) => {
                                if (index2 !== 0 && index2 !== si.length -1 ) {
                                    if (typeof s !== "number") {
                                        let parseToNumber = Number(s);
                                        b[0][index2] += parseToNumber;
                                    }
                                    else {
                                        b[0][index2] += s;
                                    }
                                }
                            })
                        }
                    })
                    // transform data to fixed decimate 
                    let c = []
                    const d = b[0].map( (item ,index) => {
                        if(index != 0){
                            return item = toFixDecimate(item, this.decimalsOption)
                        }
                        else{
                            return item
                        }
                    })
                    
                    // add cagr to total row 
                    let cagrValue = formatCagr(this.calculateCAGR(d), d[1],d[d.length-2]);
                    // const cagrValue =  this.calculateCAGR(d).toFixed(1) + '%'; 
                    d.push(cagrValue)

                    // Add D array to c
                    c.push(d)

                    //push to a array 
                    a.push(c)
                })
                this.totals.push(a);
            })
        },


        assignOutputToFigureVersus2Chart(){
            // To Show PieChart
            // [
            //     {x: "inverseSegm1", value:10, value:11},
            //     {x: "inverseSegm2", value:12, value:13},
            //     {x: "inverseSegm3", value:18, value:19},
            //     {x: "inverseSegm4", value:11, value:12},
            //     {x: "inverseSegm5", value:9,  value:10},
            // ]

            // To Show PieChart
            // [
            //     ['inverseSegm1',FY1,LY2,sum],
            //     ['inverseSegm2',FY1,LY2,sum],
            //     ['inverseSegm3',FY1,LY2,sum],
            //     ['inverseSegm4',FY1,LY2,sum],
            // ]

            for(let index = 0; index < this.output.length; index++){
                const versusValueFinalYear = [];

                this.output[index].segmentations[0].segmentationItems.forEach(
                    si => {
                        let versusValueBySegmentation = []
                        si.inverseParent.forEach(ip => {
                            let inverseSegmItemName; 


                            if(ip.regionId != null){
                                if(ip.description){
                                    inverseSegmItemName = ip.name
                                }
                                else{
                                    inverseSegmItemName = ip.region.name
                                }
                            }
                            else{
                                inverseSegmItemName = ip.name
                            }

                            let valueFirstLastYear = [
                                inverseSegmItemName, // inverseSegmItemName
                                toFixValueOptionDecimate( // FirstYearValue
                                    ip.documentProgramTopic.documentProgramValues[0].value,
                                    this.valueDisplayOption, 
                                    this.decimalsOption
                                ),
                                toFixValueOptionDecimate( // lastYearValue 
                                    ip.documentProgramTopic.documentProgramValues[ip.documentProgramTopic.documentProgramValues.length -1].value, 
                                    this.valueDisplayOption, 
                                    this.decimalsOption
                                ),
                            ]
                            versusValueBySegmentation.push(valueFirstLastYear)
                        });
                        versusValueFinalYear.push(versusValueBySegmentation);
                    }
                );
                this.toFigureVersus2ChartDataYear.push(versusValueFinalYear);

                this.toFigureVersus2ChartFirstYear = this.output[0]
                    .segmentations[0]
                    .segmentationItems[0]
                    .inverseParent[0]
                    .documentProgramTopic
                    .documentProgramValues[0]
                    .year;
                
                let documentProgramValuesLength = this.output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.length -1
                
                this.toFigureVersus2ChartLastYear = this.output[0]
                    .segmentations[0]
                    .segmentationItems[0]
                    .inverseParent[0]
                    .documentProgramTopic
                    .documentProgramValues[documentProgramValuesLength].year;
            }
            // testLog
            // console.log('this.toFigureVersus2ChartDataYear',this.toFigureVersus2ChartDataYear);
            // console.log('this.toFigureVersus2ChartFirstYear',this.toFigureVersus2ChartFirstYear);
            // console.log('this.toFigureVersus2ChartDataLastYear',this.toFigureVersus2ChartLastYear);
        },


        calculateCAGR(values){
            let val =[...values] 
            val.shift();
            val.pop()

            let initialValue = val[0]
            let finalValue = val[val.length -1];
            let n = val.length -1

            const cagr = Math.pow(finalValue / initialValue, 1/n ) - 1;
            return (cagr)?cagr * 100 :0 
        },
        formatNumber(num){
            return formatNumber(num)
        },
        compareFYFL(fy ,fl){
            const fyTotal = fy.reduce((acc, value)=> acc + parseFloat(value),0)
            const flTotal = fl.reduce((acc, value)=> acc + parseFloat(value),0)

            const totalFY = fyTotal
            const totalLY = flTotal

            return (totalFY>totalLY) ?true : false 
        },

    },
    watch: {
        'output': {
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart()
                    this.getTotalRow();
                    // figure3
                    this.assignOutputToFigureVersus2Chart();
                    
                    // this.nextStep();
                }
            },
            deep: true,
        },
        'valueDisplayOption':{  
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart()
                    this.getTotalRow();
                    
                    // figure3
                    this.assignOutputToFigureVersus2Chart();

                    // this.nextStep();
                }
            },
            deep: true,
        },
        'decimalsOption':{
            async handler(newVal) {
                if (newVal) {
                    this.cleanData();

                    this.assignOutput();
                    this.assignOutputToChart()
                    this.getTotalRow();

                    // figure3
                    this.assignOutputToFigureVersus2Chart();

                    // this.nextStep();
                }
            },
            deep: true,
        }

    }
}
</script>

<style scoped>
.topicOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.topicOutputTh {
    border: solid gray 1px;
    padding: 0 1.5% 0 1.5%;

}
</style>